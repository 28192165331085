<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список регулярностей</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_DELETED}}
      </div>

      <ul class="list-group">
        <li v-for="(regularity, index) in regularities" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="regularity.isEditing">
            <div class="input-group">
              <input v-model="regularity.name" class="form-control" placeholder="Название регулярности"/>
              <div class="input-group-text">
                <label class="form-check-label">По умолчанию</label>
                <input type="checkbox" v-model="regularity.isDefaultValue" class="form-check-input ms-2"/>
              </div>
            </div>
            <div class="btn-group">
              <button @click="updateRegularity(regularity)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(regularity)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="regularity-name">{{ regularity.name }}</span>
              <span v-if="regularity.isDefaultValue" class="badge bg-info text-dark ms-2">Значение по умолчанию</span>
            </div>
            <div class="btn-group">
              <button @click="editRegularity(regularity)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(regularity.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addRegularity" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.regularity.name" class="form-control" placeholder="Введите название регулярности"/>
          <div class="input-group-text">
            <label class="form-check-label">Стандартное значение</label>
            <input type="checkbox" v-model="this.regularity.isDefaultValue" class="form-check-input ms-2"/>
          </div>
          <button type="submit" class="btn btn-primary ms-3">Добавить регулярность</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import { isAuth } from "@/mixins/authCheck";

export default {
  name: "ListRegularity",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      regularities: [],
      regularity: {
        name: "",
        isDefaultValue: false
      }
    };
  },
  methods: {
    async getRegulatiries() {
      try {
        const response = await http.get(`/regularities`);
        this.regularities = response.data.map(regularity => ({
          ...regularity,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addRegularity(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.regularity.name,
          isDefaultValue: this.regularity.isDefaultValue
        };
        await http.post("/addRegularity", data);
        this.regularity = {
          name: "",
          isDefaultValue: false,
        };
        await this.getRegulatiries();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteRegularity(id);
      }
    },
    async deleteRegularity(id){
      try{
        await http.post(`/deleteRegularity/${id}`);
        await this.getRegulatiries();
      }catch (e){
        console.log(e)
      }
    },
    editRegularity(regularity) {
      regularity.isEditing = true;
    },
    async updateRegularity(regularity) {
      try {
        const data = {
          name: regularity.name,
          isDefaultValue: regularity.isDefaultValue
        };
        await http.post(`/updateRegularity/${regularity.id}`, data);
        regularity.isEditing = false;
        await this.getRegulatiries();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(regularity) {
      regularity.isEditing = false;
      this.getRegulatiries();
    }
  },
  mounted() {
    this.getRegulatiries();
    this.checkAuth();
  }
};
</script>