<template>
  <admin-nav-bar/>
  <div v-if="hse_detailed" class="admin-page">
    <div class="admin-content">
      <form @submit.prevent="updateHseDetailed">
      <h1>Обновить вложенный грант ВШЭ</h1>
    <div class="form-group card-shadow">
      <h3>Привязка к гранту ВШЭ</h3>
      <multiselect
          v-model="hse_detailed.hse_grandees_id"
          :options="hse_grandees"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Грант ВШЭ"
          label="hseGrandeesName"
          track-by="id"
          :searchable="true"
      >
      </multiselect>
    </div>
    <div class="form-group card-shadow">
      <h3>Актуальность</h3>
      <input class="form-check-input ms-2" type="checkbox" v-model="hse_detailed.actuality">
    </div>
    <div class="form-group card-shadow">
      <h3>Сайт</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorWebsiteInfoReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Контакты</h3>
      <multiselect
          v-model="selectedPersonEmails"
          :options="person_emails"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Контакты"
          label="personEmailSelectField"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.person_name }} {{option.email_name}}
            <button
                class="delete-button"
                @click.stop.prevent="removePersonEmail(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="personEmailModal" class="btn btn-primary">Добавить</button>
      <add-person-emails-modal ref="addPersonEmailsModal" v-show="isPersonEmailsModalVisible" @close="closePersonEmailModal"/>
    </div>
    <div class="form-group card-shadow">
      <h3>Факультет</h3>
      <multiselect
          v-model="hse_detailed.faculty_id"
          :options="faculties"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Факультет"
          label="name"
          track-by="id"
          :searchable="true"
      >
      </multiselect>
    </div>
    <div class="form-group card-shadow">
      <h3>Сроки подачи заявок</h3>
      <input  class="form-control" placeholder="Введите срок подачи заявки" type="text" v-model="hse_detailed.deadline_submission">
    </div>
    <div class="form-group card-shadow">
      <h3>Почта</h3>
      <input  class="form-control" placeholder="Введите почту" type="text" v-model="hse_detailed.email">
    </div>
    <div class="form-group card-shadow">
      <h3>Объем финансирования</h3>
      <input  class="form-control" placeholder="Введите размер финансирования" type="text" v-model="hse_detailed.financing">
    </div>
    <div class="form-group card-shadow">
      <h3>Комментарий</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorCommentReady"
      />
    </div>
    <button type="submit" class="btn btn-primary">Обновить грант</button>
      </form>
  </div>
  </div>
  <div v-else>
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import {isAuth} from "@/mixins/authCheck";
import AdminNavBar from "@/components/AdminNavBar.vue";
import addPersonEmailsModal from "@/components/modals/addPersonEmailsModal.vue";
import Multiselect from "vue-multiselect";
import {quillEditor} from "vue3-quill";
export default {
  name: "hse_detailed_grand",
  mixins: [isAuth],
  components: {
    AdminNavBar,
    Multiselect,
    quillEditor,
    addPersonEmailsModal
  },
  props: ['id'],
  data() {
    return {
      hse_detailed: null,
      person_emails: [],
      selectedPersonEmails: [],
      isPersonEmailsModalVisible: false,
      afterSelectedPersonEmails: [],
      faculties: [],
      hse_grandees: [],
      quillInstanceWebsiteInfo: "",
      quillInstanceComment: "",
      editorOptions: {
        theme: 'snow',
        placeholder: 'Введите текст',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': 1}, {'header': 2}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'direction': 'rtl'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'color': []}, {'background': []}],
            [{'align': []}],
            ['clean'],
            ['link', 'image', 'video']
          ]
        }
      }
    };
  },
  methods: {
    async updateHseDetailed(){
      try{
        const htmlWebsiteInfo = this.quillInstanceWebsiteInfo.root.innerHTML;
        const htmlComment = this.quillInstanceComment.root.innerHTML;
        var data = {
          hse_grandees_id: this.hse_detailed.hse_grandees_id.id,
          actuality: this.hse_detailed.actuality,
          website_info: htmlWebsiteInfo,
          faculty_id: this.hse_detailed.faculty_id.id,
          deadline_submission: this.hse_detailed.deadline_submission,
          email: this.hse_detailed.email,
          financing: this.hse_detailed.financing,
          comment: htmlComment
        };
        await http.post(`/updateHse_grand_detailed/${this.id}`,data);
        for(var peToAdd in this.selectedPersonEmails){
          if(!this.afterSelectedPersonEmails.some(item => item.id === this.selectedPersonEmails[peToAdd]?.id)){
            const data = {
              hse_grandees_id: null,
              hse_grandees_detailed_id: this.id,
              person_email_id: this.selectedPersonEmails[peToAdd].id
            }
            await http.post('/addGrandees_person_email',data);
          }
        }
        for(var peToDelete in this.afterSelectedPersonEmails){
          if(!this.selectedPersonEmails.some(item => item.id === this.afterSelectedPersonEmails[peToDelete]?.id)){
            await http.post(`/deleteGrandees_person_email/hseDetailedId=${this.id}/person_email_id=${this.afterSelectedPersonEmails[peToDelete].id}`);
          }
        }
        window.location.href = '/admin/hseGrandeesDetailed'
      }catch (e){
        console.log(e)
      }
    },
    async getHseDetailed() {
      try {
        const response = await http.get(`/hse_grand_detailed/${this.id}`);
        this.hse_detailed = response.data;
      } catch (e) {
        console.log(e)
      }
    },
    onEditorWebsiteInfoReady(quill) {
      this.quillInstanceWebsiteInfo = quill;
      if(this.hse_detailed.website_info){
        this.quillInstanceWebsiteInfo.root.innerHTML = this.hse_detailed.website_info;
      }
    },
    onEditorCommentReady(quill) {
      this.quillInstanceComment = quill;
      if(this.hse_detailed.comment){
        this.quillInstanceComment.root.innerHTML = this.hse_detailed.comment;
      }
    },
    async getFaculties() {
      try {
        const response = await http.get("/faculties");
        this.faculties = response.data;
      } catch (e) {
        console.log(e)
      }
    },
    async getHseGrandees() {
      try {
        const response = await http.get("/hse_grandees");
        response.data.forEach(a => {
          const firstPart = a.format_name.replace(/<[^>]*>/g, '');
          const secondPart = a.format_description.replace(/<[^>]*>/g, '');
          a.hseGrandeesName = `${firstPart} - ${secondPart}`;
        })
        this.hse_grandees = response.data;
      } catch (e) {
        console.log(e)
      }
    },
    async getPersonEmails() {
      try {
        var response = await http.get('/person_emails/names');
        response.data.forEach(a => {
          a.personEmailSelectField = `${a.person_name} - ${a.email_name}`
        })
        this.person_emails = response.data;
      } catch (e) {
        console.log(e)
      }
    },
    async removePersonEmail(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try {
        await http.post(`/deletePerson_email/${id}`);
        await this.getPersonEmails();
      } catch (e) {
        console.log(e)
      }
      }
    },
    personEmailModal() {
      this.isPersonEmailsModalVisible = true;
      this.$refs.addPersonEmailsModal.getAvailableEmails();
    },
    async closePersonEmailModal() {
      this.isPersonEmailsModalVisible = false;
      await this.getPersonEmails();
    },
    async getSelectedValues() {
      try{
        const facultyValue = await http.get(`/faculty/${this.hse_detailed.faculty_id}`);
        this.hse_detailed.faculty_id = facultyValue.data;

        var hseValue = await http.get(`/hse_grand/${this.hse_detailed.hse_grandees_id}`);
        const firstPart = hseValue.data.format_name.replace(/<[^>]*>/g, '');
        const secondPart = hseValue.data.format_description.replace(/<[^>]*>/g, '');
        hseValue.data.hseGrandeesName = `${firstPart} - ${secondPart}`;
        this.hse_detailed.hse_grandees_id = hseValue.data;

        const peValues = await http.get(`/grandees_person_emails/byHseDetailedId/${this.id}`);
        peValues.data.forEach(a => {
          a.personEmailSelectField = `${a.person_name} - ${a.email_name}`
        })
        this.selectedPersonEmails = peValues.data;
        this.afterSelectedPersonEmails = JSON.parse(JSON.stringify(peValues.data));
        console.log(peValues.data);
    }catch(e) {
      console.log(e)
    }
    }
  },
  async mounted() {
    await this.checkAuth();
    await this.getFaculties();
    await this.getPersonEmails();
    await this.getHseGrandees();
    await this.getHseDetailed();
    await this.getSelectedValues();
  }
}
</script>

<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "../../../node_modules/quill/dist/quill.snow.css";

.custom-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-right: 30px; /* Для отступа под крестик */
}

.delete-button {
  font-size: 24px; /* Сделаем крестик больше */
  color: rgba(231, 38, 38, 0.5); /* Полупрозрачный */
  position: absolute;
  right: 10px; /* Отступ от правого края */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: auto; /* Оставляем возможность клика на кнопку */
  background: transparent; /* Убираем фон кнопки */
  border: 10px; /* Убираем границу */
  z-index: 10; /* Поверх всего */
  cursor: pointer;
}

.delete-button:hover {
  color: rgba(0, 0, 0, 0.7); /* Добавим небольшой эффект при наведении */
}

.test {
  outline-style: dotted;
  margin: 5px;
}

#quill-editor {
  height: 1000px;
}
.form-control {
  word-wrap: break-word!important;
  word-break: break-all !important;
}
</style>