<template>
  <div v-if="hse_grand" class="container-fluid grand-details" style="width: 90% !important; min-width: 450px !important; margin-left: 5% !important; margin-right: 5% !important;">
    <p class="icon"><img src="@/icons/left-arrow-svgrepo-com.svg" @click.prevent="this.$router.go(-1)"></p>
    <b class="grand-name">{{ hse_grand.format_name }}</b>
    <hr class="my-2" style="border-color: #EAEAEA; width: 100%">
    <p class="grand-description">{{ hse_grand.format_description }}</p>
    <hr class="my-2" style="border-color: #EAEAEA; width: 100%">
    <p class="grand-field"><b class="field-title">Конкурсы и гранты НИУ ВШЭ</b></p>
    <p class="grand-field"><b class="field-title">Тип исследования:</b> <span>{{ type_of_researches.map(res => res.name).join(', ') || 'Не указано' }}</span></p>
    <p class="grand-field"><b class="field-title">Требования к результату:</b> <span>{{ result_requirements.map(req => req.name).join(', ') || 'Не указано' }}</span></p>
    <p class="grand-field"><b class="field-title">Научные направления:</b> <span>{{ science_branches.map(branch => branch.name).join(', ') || 'Не указано' }}</span></p>
    <p class="grand-field"><b class="field-title">Срок реализации:</b> <span>{{ hse_grand.deadline_name || 'Не указано' }}</span></p>
    <p class="grand-field"><b class="field-title">Размер финансирования:</b> <span>{{ financing.map(finv => finv.name).join(', ') || 'Не указано' }}</span></p>
    <p class="grand-field"><b class="field-title">Тип конкурса:</b> <span>{{ hse_grand.competition_type_name || 'Не указано' }}</span></p>
    <hr class="my-3" style="border-color: #EAEAEA; width: 100%">
    <h2 class="grand-section-title"> Периодичность</h2>
    <div v-if="hse_grand.regularity_name ==='Нерегулярный'">{{ hse_grand.relevance }}</div>
    <div v-if="hse_grand.regularity_name ==='Регулярный'">{{ hse_grand.period_name }}</div>

    <h2 class="grand-section-title">Кто может подать заявку</h2>
    <div v-html="hse_grand.apply_needs"></div>

    <h2 class="grand-section-title">Кто организует конкурс и осуществляет финансирование</h2>
    <div v-html="hse_grand.jury"></div>

    <h2 class="grand-section-title">Требования к участникам</h2>
    <div v-html="hse_grand.participant_requirements"></div>

    <h2 class="grand-section-title">Срок реализации проекта</h2>
    <div v-html="hse_grand.deadline_comment"></div>

    <h2 class="grand-section-title">Условия и размер финансирования</h2>
    <div v-html="hse_grand.financing_comment"></div>

    <h2 class="grand-section-title">Прекращение или сокращение финансирования</h2>
    <div v-html="hse_grand.funding_reduction"></div>

    <h2 class="grand-section-title">Права и требования к указанию аффилиации</h2>
    <div v-html="hse_grand.affilation"></div>
    <h2 class="grand-section-title">Конкурсные треки</h2>
    <div v-html="hse_grand.area"></div>

    <h2 class="grand-section-title">Критерии оценки заявок</h2>
      <div v-for="(criteria, index) in criterias" :key="index" ><div v-html="criteria.direction_criteria"/></div>

    <h2 class="grand-section-title">Страница конкурса</h2>
    <div v-html="hse_grand.format_website"></div>
    <h2 class="grand-section-title">Положение и регламенты</h2>
    <div v-html="hse_grand.regulations"></div>
    <h2 class="grand-section-title">Контакты</h2>
    <div v-for="(emails, person) in groupedPE" :key="person" class="contact-item">
      <p><b>{{ person }}:</b> {{ emails.join(', ') }}</p>
    </div>
    <hr class="my-3" style="border-color: #EAEAEA; width: 100%">
    <div v-if="hseDetailed.length>0 && hse_grand">
      <h2 class="grand-section-title">Вложенные гранты</h2>
      <table class="table table-bordered table-striped table-hover table-responsive-md grand-details-table">
        <thead>
        <tr>
          <th>Вебсайт</th>
          <th>Факультет</th>
          <th>Дедлайн подачи заявок</th>
          <th>Контакты</th>
          <th>Email</th>
          <th>Финансирование</th>
          <th colspan="5">Комментарий</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(detail, index) in hseDetailed" :key="index">
          <td v-html="detail.website_info || 'Не указано'"></td>
          <td>{{ detail.faculty_name || 'Не указано' }}</td>
          <td>{{ detail.deadline_submission || 'Не указано' }}</td>
          <td><div v-for="(person, index) in detail.person_info" :key="index" class="contact-item">
            <p>{{person}}</p>
          </div></td>
          <td>{{ detail.email || 'Не указано' }}</td>
          <td>{{ detail.financing || 'Не указано' }}</td>
          <td colspan="5" v-html="detail.comment || 'Не указано'"></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else>
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
  <div v-if="isPageLoaded">
    <footer-user-menu/>
  </div>
</template>



<script>
import http from "../../http-common";
import FooterUserMenu from "@/components/FooterUserMenu.vue";
export default {
  name: "HseGrandUser",
  components: {
    FooterUserMenu
  },
  props: ['id'],
  data() {
    return {
      hse_grand: null,
      person_emails: [],
      person_emails_detailed: [],
      groupedPE: {},
      result_requirements: [],
      type_of_researches: [],
      science_branches: [],
      financing: [],
      criterias: [],
      hseDetailed: [],
      isPageLoaded: false
    }
  },
  methods: {
    formatUrl(url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return 'https://' + url;
      }
      return url;
    },
    async getHseGrand() {
      try{
        const response = await http.get(`/hse_grand_info/${this.id}`);
        this.hse_grand = await response.data[0];
        this.hse_grand.format_name = this.hse_grand.format_name.replace(/<[^>]*>/g, '');
        this.hse_grand.format_description = this.hse_grand.format_description.replace(/<[^>]*>/g, '');
        this.hse_grand.relevance = this.hse_grand.relevance.replace(/<[^>]*>/g, '');
        this.isPageLoaded = true;
      }catch (e){
        console.log(e)
      }
    },
    async getHseDetailed(){
      try{
        const response = await http.get(`/hse_grandees_detailed_info/${this.id}`);
        this.hseDetailed = response.data;
        this.hseDetailed.forEach(detail => {
          const relatedEmails = this.person_emails_detailed.filter(email => email.hse_grandees_detailed_id === detail.id);
          detail.person_info = relatedEmails.map(email => `${email.person_name} ${email.email_name}`);
        });
        // console.log(this.hseDetailed);
      }catch (e){
        console.log(e)
      }
    },
    async getPersonEmail(){
      try{
        const response = await http.get(`/grandees_person_emails/byHseId/${this.id}`);
        this.person_emails = response.data;
        this.groupedPE = this.groupEmailsByPerson(this.person_emails);
        const responseDetailed = await http.get(`/grandees_person_emails/byIncludedHseId/${this.id}`);
        this.person_emails_detailed = responseDetailed.data;
        console.log(responseDetailed.data);
      }catch (e){
        console.log(e)
      }
    },
    groupEmailsByPerson(emails) {
      const grouped = {};

      emails.forEach(email => {
        if (!grouped[email.person_name]) {
          grouped[email.person_name] = [];
        }
        grouped[email.person_name].push(email.email_name);
      });

      return grouped;
    },
    async getResultRequirements(){
      try{
        const response = await http.get(`/grandees_result_requirements/byHseId/${this.id}`);
        this.result_requirements = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getTypeOfResearches(){
      try{
        const response = await http.get(`/grandees_type_of_researches/byHseId/${this.id}`);
        this.type_of_researches = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getScienceBranches(){
      try{
        const response = await http.get(`/grandees_science_branches/byHseId/${this.id}`);
        this.science_branches = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getFinancing(){
      try{
        const response = await http.get(`/financing/byHseId/${this.id}`);
        this.financing = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getCriterias(){
      try{
        const response = await http.get(`/hse_grandees_criteria/byHseId/${this.id}`);
        this.criterias = response.data;
      }catch (e){
        console.log(e)
      }
    }
  },
  async mounted() {
    await this.getHseGrand();
    await this.getPersonEmail();
    await this.getResultRequirements();
    await this.getTypeOfResearches();
    await this.getScienceBranches();
    await this.getFinancing();
    await this.getCriterias();
    await this.getHseDetailed();
  }
}
</script>

<style scoped>
.grand-details-table th:nth-child(8),
.grand-details-table td:nth-child(8) {
  width: 40%;
}
.grand-details-table {
  width: 100% !important;
  margin-top: 20px !important;
  color: #000 !important;
  border-color: #EAEAEA !important;
  background-color: #fff !important;
  font-size: 14px !important;
}

.grand-details-table th {
  background-color: #f8f9fa !important;
  font-weight: bold !important;
  color: #003366 !important;
  text-align: left !important;
  font-size: 14px !important; /* Уменьшенный шрифт для заголовков */
}

.grand-details-table td {
  color: #000 !important;
  text-align: left !important;
  vertical-align: middle !important;
  padding: 8px !important;
  word-wrap: break-word !important; /* Перенос текста на новую строку */
  overflow-wrap: break-word !important; /* Дополнительно для переносов */
  font-size: 12px !important;
  white-space: normal !important;
}

.grand-details-table a {
  color: #003366 !important;
  text-decoration: none !important;
  font-size: 14px !important; /* Уменьшенный шрифт для ссылок */
}

.grand-details-table a:hover {
  color: #102D69 !important;
  text-decoration: underline !important;
}
.container-fluid {
  width: 90% !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  text-align: left !important;
}

.grand-section-title {
  font-size: 20px !important;
  color: #000 !important;
  font-weight: bold !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  text-align: left !important;
}

.grand-divider {
  border-color: #EAEAEA !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.grand-section-content {
  font-size: 16px !important;
  color: #333 !important;
  margin-bottom: 15px !important;
}

/* Стили для format_name */
.grand-name {
  color: #000000 !important;
  font-weight: bold !important;
  font-size: 32px !important;
  text-align: left !important; /* Выровнять текст по левой стороне */
  margin-bottom: 5px !important;
  word-wrap: break-word !important;
  line-height: 1.2 !important;
}

/* Стили для format_description */
.grand-description {
  font-size: 20px !important;
  text-align: left !important; /* Выровнять текст по левой стороне */
  color: #000000 !important;
  margin-bottom: 10px !important;
  word-wrap: break-word !important;
}
div {
  word-wrap: break-word !important;
}

/* Линия после заголовка и описания */
.grand-divider {
  border-color: #EAEAEA !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

/* Стили для полей Актуальность, Регулярность и Периодичность */
.grand-meta {
  font-size: 18px !important;
  color: #009B64 !important; /* Зеленый цвет */
  font-weight: bold !important;
  text-align: left !important; /* Выровнять текст по левой стороне */
  margin-bottom: 5px !important;
}

.grand-meta span {
  font-weight: normal !important;
  color: #000 !important; /* Черный цвет текста для значений */
  text-align: left !important; /* Выровнять текст по левой стороне */
}

.grand-field {
  font-size: 18px !important;
  color: #000000 !important;
  margin-bottom: 10px !important;
}

.field-title {
  color: #003366 !important;
  font-weight: bold !important;
  text-align: left !important;
  margin-right: 5px !important;
}

.grand-field span {
  font-weight: normal !important;
  color: #000 !important;
}
.icon img {
  width: 3%;
  cursor: pointer;
  height: 3%;
  margin-bottom: 0px !important;
}
td{
  word-wrap: break-word!important;
}
</style>


