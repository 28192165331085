<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список регионов</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_DELETED}}
      </div>

      <ul class="list-group">
        <li v-for="(region, index) in regions" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="region.isEditing">
            <div class="input-group">
              <input v-model="region.name" class="form-control" placeholder="Название региона"/>
            </div>
            <div class="btn-group">
              <button @click="updateRegion(region)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(region)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="region-name">{{ region.name }}</span>
            </div>
            <div class="btn-group">
              <button @click="editRegion(region)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(region.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addRegion" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.region.name" class="form-control" placeholder="Введите название региона"/>
          <button type="submit" class="btn btn-primary ms-3">Добавить регион</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import { isAuth } from "@/mixins/authCheck";

export default {
  name: "ListRegion",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      regions: [],
      region: {
        name: ""
      }
    };
  },
  methods: {
    async getRegions() {
      try {
        const response = await http.get(`/regions`);
        this.regions = response.data.map(region => ({
          ...region,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addRegion(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.region.name
        };
        await http.post("/addRegion", data);
        this.region = {
          name: ""
        };
        await this.getRegions();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteRegion(id);
      }
    },
    async deleteRegion(id) {
      try {
        await http.post(`/deleteRegion/${id}`);
        await this.getRegions();
      } catch (e) {
        console.log(e);
      }
    },
    editRegion(region) {
      region.isEditing = true;
    },
    async updateRegion(region) {
      try {
        const data = {
          name: region.name
        };
        await http.post(`/updateRegion/${region.id}`, data);
        region.isEditing = false;
        await this.getRegions();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(region) {
      region.isEditing = false;
      this.getRegions();
    }
  },
  mounted() {
    this.getRegions();
    this.checkAuth();
  }
};
</script>
