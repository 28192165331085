<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список федеральных округов</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_DELETED}}
      </div>

      <ul class="list-group">
        <li v-for="(fd, index) in federal_districts" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="fd.isEditing">
            <div class="input-group">
              <input v-model="fd.name" class="form-control" placeholder="Название федерального округа"/>
            </div>
            <div class="btn-group">
              <button @click="updateFederalDistrict(fd)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(fd)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="federal-district-name">{{ fd.name }}</span>
            </div>
            <div class="btn-group">
              <button @click="editFederalDistrict(fd)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(fd.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addFederalDistrict" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.federal_district.name" class="form-control" placeholder="Введите название федерального округа"/>
          <button type="submit" class="btn btn-primary ms-3">Добавить федеральный округ</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import {isAuth} from "@/mixins/authCheck";

export default {
  name: "ListFederalDistrict",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      federal_districts: [],
      federal_district: {
        name: ""
      }
    };
  },
  methods: {
    async getFederalDistrict() {
      try {
        const response = await http.get(`/federal_districts`);
        this.federal_districts = response.data.map(fd => ({
          ...fd,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addFederalDistrict(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.federal_district.name
        };
        await http.post("/addFederal_district", data);
        this.federal_district = {
          name: ""
        };
        await this.getFederalDistrict();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteFederalDistrict(id);
      }
    },
    async deleteFederalDistrict(id) {
      try {
        await http.post(`/deleteFederal_district/${id}`);
        await this.getFederalDistrict();
      } catch (e) {
        console.log(e);
      }
    },
    editFederalDistrict(fd) {
      fd.isEditing = true;
    },
    async updateFederalDistrict(fd) {
      try {
        const data = {
          name: fd.name
        };
        await http.post(`/updateFederal_district/${fd.id}`, data);
        fd.isEditing = false;
        await this.getFederalDistrict();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(fd) {
      fd.isEditing = false;
      this.getFederalDistrict();
    }
  },
  mounted() {
    this.getFederalDistrict();
    this.checkAuth();
  }
};
</script>
