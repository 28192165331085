export function getDataWithExpiration(key, expirationInMinutes) {
    const dataString = localStorage.getItem(key);

    if (!dataString) {
        return null;
    }

    const data = JSON.parse(dataString);
    const currentTime = new Date().getTime();
    const expirationTime = expirationInMinutes * 60 * 1000;
    console.log(currentTime - data.timestamp)
    if (currentTime - data.timestamp > expirationTime) {
        localStorage.removeItem(key);
        return null;
    }

    return data.value;
}

export function saveDataWithTimestamp(key, value) {
    const data = {
        value: value,
        timestamp: new Date().getTime()
    };
    localStorage.setItem(key, JSON.stringify(data));
}