<template>
  <div v-if="external_grand" class="container-fluid grand-details" style="width: 90% !important; min-width: 450px !important; margin-left: 5% !important; margin-right: 5% !important;">
    <p class="icon"><img src="@/icons/left-arrow-svgrepo-com.svg" @click.prevent="this.$router.go(-1)"></p>
    <b class="grand-name">{{ external_grand.organization }}</b>
    <hr class="my-2" style="border-color: #EAEAEA; width: 100%">
    <p class="grand-description">{{ external_grand.description }}</p>
    <hr class="my-2" style="border-color: #EAEAEA; width: 100%">
    <p class="grand-field"><b class="field-title">Конкурсы и гранты внешних институтов развития</b></p>
    <p class="grand-field"><b class="field-title">Тип исследования:</b> <span>{{ type_of_researches.map(res => res.name).join(', ') || 'Не указано' }}</span></p>
    <p class="grand-field"><b class="field-title">Требования к результату:</b> <span>{{ result_requirements.map(req => req.name).join(', ') || 'Не указано' }}</span></p>
    <p class="grand-field"><b class="field-title">Научные направления:</b> <span>{{ science_branches.map(branch => branch.name).join(', ') || 'Не указано' }}</span></p>
    <p class="grand-field"><b class="field-title">Срок реализации:</b> <span>{{ external_grand.deadline_name || 'Не указано' }}</span></p>
    <p class="grand-field"><b class="field-title">Размер финансирования:</b> <span>{{ financing.map(finv => finv.name).join(', ') || 'Не указано' }}</span></p>
    <hr class="my-3" style="border-color: #EAEAEA; width: 100%">
    <h2 class="grand-section-title">Периодичность</h2>
    <div v-if="external_grand.regularity_name ==='Нерегулярный'">{{ external_grand.relevance ? "Актуальный" : "Неактуальный" }}</div>
    <div v-if="external_grand.regularity_name ==='Регулярный'">{{ external_grand.period_name || 'Не указан' }}</div>
    <h2 class="grand-section-title">Требования к участникам</h2>
    <div v-html="external_grand.member_requirements"></div>
    <h2 class="grand-section-title">Срок реализации проекта</h2>
    <div v-html="external_grand.deadline_comment"></div>
    <h2 class="grand-section-title">Условия и размер финансирования</h2>
    <div v-html="external_grand.financing_comment"></div>
    <h2 class="grand-section-title">Прекращение или сокращение финансирования</h2>
    <div v-html="external_grand.funding_reduction"></div>
    <h2 class="grand-section-title">Конкурсные треки</h2>
    <div v-html="external_grand.topic"></div>
    <h2 class="grand-section-title">Критерии оценки заявок</h2>
    <div v-html="external_grand.criteria"></div>
    <h2 class="grand-section-title">Приоритетные направления</h2>
    <div v-html="external_grand.priority_area"></div>
    <h2 class="grand-section-title">Страница конкурса</h2>
    <div v-html="external_grand.website_info"></div>
    <h2 class="grand-section-title">Положение/регламенты</h2>
    <div v-html="external_grand.regulations"></div>
    <h2 class="grand-section-title">Об организации</h2>
    <a :href="formatUrl(external_grand.website)" target="_blank" rel="noopener noreferrer">{{external_grand.website}}</a>
    <h2 class="grand-section-title">Сфера деятельности организации</h2>
    <div v-html="external_grand.activity_field"></div>
    <h2 class="grand-section-title">Формы и виды поддержки</h2>
    <div v-html="external_grand.support"></div>
    <h2 class="grand-section-title">Год создания</h2>
    <span>{{external_grand.foundation_year}}</span>
    <h2 class="grand-section-title">Контакты</h2>
    <span>Федеральный округ: {{external_grand.federal_district_name}}</span><br/>
    <span>Регион: {{external_grand.region_name}}</span><br/>
    <span>Город: {{external_grand.city_name}}</span><br/>
    <span>Адрес: {{external_grand.address}}</span><br/>
    <span>Телефон: {{external_grand.phone_number}}</span><br/>
    <span>Факс: {{external_grand.fax}}</span><br/>
    <span>Электронная почта: {{external_grand.email}}</span><br/>
    <hr class="my-3" style="border-color: #EAEAEA; width: 100%">
  </div>
  <div v-else>
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
  <div v-if="isPageLoaded">
    <footer-user-menu/>
  </div>
</template>



<script>
import http from "../../http-common";
import FooterUserMenu from "@/components/FooterUserMenu.vue";
export default {
  name: "ExternalGrandUser",
  components: {
    FooterUserMenu
  },
  props: ['id'],
  data() {
    return {
      external_grand: null,
      result_requirements: [],
      type_of_researches: [],
      science_branches: [],
      financing: [],
      isPageLoaded: false
    }
  },
  methods: {
    formatUrl(url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return 'https://' + url;
      }
      return url;
    },
    async getExternalGrand() {
      try{
        const response = await http.get(`/external_grand_info/${this.id}`);
        this.external_grand = await response.data[0];
        this.external_grand.organization = this.external_grand.organization.replace(/<[^>]*>/g, '');
        this.external_grand.description = this.external_grand.description.replace(/<[^>]*>/g, '');
        this.isPageLoaded = true;
      }catch (e){
        console.log(e)
      }
    },
    async getResultRequirements(){
      try{
        const response = await http.get(`/grandees_result_requirements/byExternalId/${this.id}`);
        this.result_requirements = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getTypeOfResearches(){
      try{
        const response = await http.get(`/grandees_type_of_researches/byExternalId/${this.id}`);
        this.type_of_researches = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getScienceBranches(){
      try{
        const response = await http.get(`/grandees_science_branches/byExternalId/${this.id}`);
        this.science_branches = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getFinancing(){
      try{
        const response = await http.get(`/financing/byExternalId/${this.id}`);
        this.financing = response.data;
      }catch (e){
        console.log(e)
      }
    }
  },
  async mounted() {
    await this.getExternalGrand();
    await this.getResultRequirements();
    await this.getTypeOfResearches();
    await this.getScienceBranches();
    await this.getFinancing();
  }
}
</script>

<style scoped>
.container-fluid {
  width: 90% !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  text-align: left !important;
}

.grand-section-title {
  font-size: 20px !important;
  color: #000 !important;
  font-weight: bold !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  text-align: left !important;
}

.grand-divider {
  border-color: #EAEAEA !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.grand-section-content {
  font-size: 16px !important;
  color: #333 !important;
  margin-bottom: 15px !important;
}

/* Стили для format_name */
.grand-name {
  color: #000000 !important;
  font-weight: bold !important;
  font-size: 32px !important;
  text-align: left !important; /* Выровнять текст по левой стороне */
  margin-bottom: 5px !important;
  word-wrap: break-word !important;
  line-height: 1.2 !important;
}

/* Стили для format_description */
.grand-description {
  font-size: 20px !important;
  text-align: left !important; /* Выровнять текст по левой стороне */
  color: #000000 !important;
  margin-bottom: 10px !important;
  word-wrap: break-word !important;
}

/* Линия после заголовка и описания */
.grand-divider {
  border-color: #EAEAEA !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

/* Стили для полей Актуальность, Регулярность и Периодичность */
.grand-meta {
  font-size: 18px !important;
  color: #009B64 !important; /* Зеленый цвет */
  font-weight: bold !important;
  text-align: left !important; /* Выровнять текст по левой стороне */
  margin-bottom: 5px !important;
}

.grand-meta span {
  font-weight: normal !important;
  color: #000 !important; /* Черный цвет текста для значений */
  text-align: left !important; /* Выровнять текст по левой стороне */
}

.grand-field {
  font-size: 18px !important;
  color: #000000 !important;
  margin-bottom: 10px !important;
}

.field-title {
  color: #003366 !important;
  font-weight: bold !important;
  text-align: left !important;
  margin-right: 5px !important;
}
div {
  word-wrap: break-word !important;
}
.grand-field span {
  font-weight: normal !important;
  color: #000 !important;
}
.icon img {
  width: 3%;
  cursor: pointer;
  height: 3%;
  margin-bottom: 0px !important;
}
</style>


