<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Управление контактами пользователей</h1>

      <div class="alert alert-warning" role="alert">
        При удалении элемента, все связанные данные также будут удалены. Рекомендуется избегать удаления.
      </div>

      <div class="row">
        <!-- Первый столбец: список людей -->
        <div class="col-md-4">
          <h2>Люди</h2>
          <ul class="list-group">
            <li v-for="(person, index) in persons" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
              <template v-if="person.isEditing">
                <input v-model="person.name" class="form-control" placeholder="Имя человека"/>
                <div class="btn-group">
                  <button @click="updatePerson(person)" class="btn btn-success">Сохранить</button>
                  <button @click="cancelEdit(person)" class="btn btn-danger">Отмена</button>
                </div>
              </template>
              <template v-else>
                <span>{{ person.name }}</span>
                <div class="btn-group">
                  <button @click="editRegion(person)" class="btn btn-primary">Изменить</button>
                  <button class="btn btn-danger" type="button" @click="confirmDelete(person.id, 'person')">Удалить</button>
                </div>
              </template>
            </li>
          </ul>
          <form @submit="addPerson" class="mt-4">
            <div class="input-group mb-3">
              <input v-model="this.person.name" class="form-control" placeholder="Введите имя человека"/>
              <button type="submit" class="btn btn-primary ms-3">Добавить</button>
            </div>
          </form>
        </div>

        <!-- Второй столбец: список email-адресов -->
        <div class="col-md-4">
          <h2>Почты</h2>
          <ul class="list-group">
            <li v-for="(email, index) in emails" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
              <template v-if="email.isEditing">
                <input v-model="email.name" class="form-control" placeholder="Email"/>
                <div class="btn-group">
                  <button @click="updateEmail(email)" class="btn btn-success">Сохранить</button>
                  <button @click="cancelEdit(email)" class="btn btn-danger">Отмена</button>
                </div>
              </template>
              <template v-else>
                <span>{{ email.name }}</span>
                <div class="btn-group">
                  <button @click="editRegion(email)" class="btn btn-primary">Изменить</button>
                  <button class="btn btn-danger" type="button" @click="confirmDelete(email.id, 'email')">Удалить</button>
                </div>
              </template>
            </li>
          </ul>
          <form @submit="addEmail" class="mt-4">
            <div class="input-group mb-3">
              <input v-model="this.email.name" class="form-control" placeholder="Введите email"/>
              <button type="submit" class="btn btn-primary ms-3">Добавить</button>
            </div>
          </form>
        </div>

        <!-- Третий столбец: Таблица связи людей и email-адресов -->
        <div class="col-md-4">
          <h2>Контакты пользователей</h2>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Человек</th>
              <th>Email</th>
              <th>Действия</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(personEmail, index) in person_emails" :key="index">
              <td>{{ personEmail.person_name }}</td>
              <td>{{ personEmail.email_name }}</td>
              <td>
                <button class="btn btn-danger" type="button" @click="confirmDelete(personEmail.id, 'personEmail')">Удалить</button>
              </td>
            </tr>
            <!-- Поля добавления связей Человек-Email -->
<!--            <tr>-->
<!--              <td>-->
<!--                <select v-model="person_email.person_id" class="form-select">-->
<!--                  <option disabled value="">Выберите человека</option>-->
<!--                  <option v-for="person in persons" :key="person.id" :value="person.id">{{ person.name }}</option>-->
<!--                </select>-->
<!--              </td>-->
<!--              <td>-->
<!--                <select v-model="person_email.email_id" class="form-select">-->
<!--                  <option disabled value="">Выберите email</option>-->
<!--                  <option v-for="email in emails" :key="email.id" :value="email.id">{{ email.name }}</option>-->
<!--                </select>-->
<!--              </td>-->
<!--              <td>-->
<!--                <button @click="addPersonEmail" class="btn btn-primary">Добавить</button>-->
<!--              </td>-->
<!--            </tr>-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import { isAuth } from "@/mixins/authCheck";

export default {
  name: "ListPersonEmail",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      person_emails: [],
      person_email: {
        person_id: null,
        email_id: null,
        person_name: "",
        email_name: ""
      },
      persons: [],
      emails: [],
      person: {
        name: ""
      },
      email: {
        name: ""
      }
    };
  },
  methods: {
    async getPersonEmails() {
      try {
        const response = await http.get(`/person_emails/names`);
        this.person_emails = response.data.map(pe => ({
          ...pe,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async getPersons() {
      try {
        const response = await http.get(`/persons`);
        this.persons = response.data.map(p => ({
          ...p,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async getEmails() {
      try {
        const response = await http.get(`/emails`);
        this.emails = response.data.map(e => ({
          ...e,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addPersonEmail(e) {
      e.preventDefault();
      try {
        const data = {
          person_id: this.person_email.person_id,
          email_id: this.person_email.email_id,
        };
        await http.post("/addPerson_email", data);
        this.person_email = {
          person_id: null,
          email_id: null
        };
        await this.updateAllData();
      } catch (e) {
        console.log(e);
      }
    },
    async addPerson(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.person.name
        };
        await http.post("/addPerson", data);
        this.person = {
          name: ""
        };
        await this.updateAllData();
      } catch (e) {
        console.log(e);
      }
    },
    async addEmail(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.email.name
        };
        await http.post("/addEmail", data);
        this.email = {
          name: ""
        };
        await this.updateAllData();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id, type) {
      const confirmMessage = "Вы уверены, что хотите удалить этот элемент?";
      if (confirm(confirmMessage)) {
        if (type === 'person') {
          this.deletePerson(id);
        } else if (type === 'email') {
          this.deleteEmail(id);
        } else if (type === 'personEmail') {
          this.deletePersonEmail(id);
        }
      }
    },
    async deletePersonEmail(id){
      try{
        await http.post(`/deletePerson_email/${id}`);
        await this.updateAllData();
      }catch (e){
        console.log(e)
      }
    },
    async deletePerson(id){
      try{
        await http.post(`/deletePerson/${id}`);
        await this.updateAllData();
      }catch (e){
        console.log(e)
      }
    },
    async deleteEmail(id){
      try{
        await http.post(`/deleteEmail/${id}`);
        await this.updateAllData();
      }catch (e){
        console.log(e)
      }
    },
    editRegion(obj) {
      obj.isEditing = true;
    },
    cancelEdit(obj) {
      obj.isEditing = false;
      this.updateAllData();
    },
    async updatePersonEmail(pe) {
      try {
        const data = {
          person_id: pe.person_id,
          email_id: pe.email_id
        };
        await http.post(`/updatePerson_email/${pe.id}`, data);
        pe.isEditing = false;
        await this.updateAllData();
      } catch (e) {
        console.log(e);
      }
    },
    async updatePerson(p) {
      try {
        const data = {
          name: p.name
        };
        await http.post(`/updatePerson/${p.id}`, data);
        p.isEditing = false;
        await this.updateAllData();
      } catch (e) {
        console.log(e);
      }
    },
    async updateEmail(e) {
      try {
        const data = {
          name: e.name
        };
        await http.post(`/updateEmail/${e.id}`, data);
        e.isEditing = false;
        await this.updateAllData();
      } catch (e) {
        console.log(e);
      }
    },
    async updateAllData() {
      await this.getPersonEmails();
      await this.getPersons();
      await this.getEmails();
    }
  },
  mounted() {
    this.updateAllData();
    this.checkAuth();
  }
};
</script>

