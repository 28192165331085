<template>
  <div v-if="combined_data">
  <div class="container-fluid mt-3" style="width: 90% !important; min-width: 450px !important; margin-left: 5% !important; margin-right: 5% !important;">
    <div class="d-flex justify-content-start align-items-center mb-3">
      <h5 class="mb-0">Фильтры</h5>
    </div>
    <hr class="my-3" style="border: none !important;
                            border-top: 2px solid #EAEAEA !important;
                            width: 90% !important;
                            margin: 10px auto 5px auto !important;">

    <div class="row">
      <div class="col-md-3">
        <div class="filters p-3">
          <div class="justify-content-between filter-header mb-2 mt-3" @click="toggleResearchTypeFilter" href="#">
            <b><p class="mb-2 font-weight-bold d-flex justify-content-between align-items-center text-black">
              Тип исследования
              <svg
                  :class="isResearchTypeOpen ? 'rotate-icon' : ''"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14" height="14"
                  fill="currentColor"
                  class="bi bi-chevron-down arrow-icon"
                  viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </p></b>
          </div>

          <transition name="slide">
            <div v-if="isResearchTypeOpen" class="filter-options">
              <div v-for="(researchType, index) in filterValuesTypeOfResearch" :key="index" class="form-check">
                <label class="form-check-label d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" :value="researchType.id" v-model="selectedResearchTypes"/>
                  <span class="ms-2">{{ researchType.name }}</span>
                </label>
              </div>
            </div>
          </transition>

          <div class="justify-content-between filter-header mb-2 mt-3" @click="toggleResultRequirementsFilter" href="#">
            <b><p class="mb-2 font-weight-bold d-flex justify-content-between align-items-center text-black">
              Требование к результату
              <svg
                  :class="isResultResuirementsOpen ? 'rotate-icon' : ''"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14" height="14"
                  fill="currentColor"
                  class="bi bi-chevron-down arrow-icon"
                  viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </p></b>
          </div>

          <transition name="slide">
            <div v-if="isResultResuirementsOpen" class="filter-options">
              <div v-for="(rr, index) in filterResultRequirements" :key="index" class="form-check">
                <label class="form-check-label d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" :value="rr.id" v-model="selectedResultRequirements"/>
                  <span class="ms-2">{{ rr.name }}</span>
                </label>
              </div>
            </div>
          </transition>


          <div class="justify-content-between filter-header mb-2 mt-3" @click="toggleScienceBranchesFilter" href="#">
            <b><p class="mb-2 font-weight-bold d-flex justify-content-between align-items-center text-black">
              Научные направления
              <svg
                  :class="isScienceBranchOpen ? 'rotate-icon' : ''"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14" height="14"
                  fill="currentColor"
                  class="bi bi-chevron-down arrow-icon"
                  viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </p></b>
          </div>

          <transition name="slide">
            <div v-if="isScienceBranchOpen" class="filter-options">
              <div v-for="(sb, index) in filterScienceBranches" :key="index" class="form-check">
                <label class="form-check-label d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" :value="sb.id" v-model="selectedScienceBranches"/>
                  <span class="ms-2">{{ sb.name }}</span>
                </label>
              </div>
            </div>
          </transition>

          <div class="justify-content-between filter-header mb-2 mt-3" @click="toggleDeadlinesFilter" href="#">
            <b><p class="mb-2 font-weight-bold d-flex justify-content-between align-items-center text-black">
              Срок реализации
              <svg
                  :class="isDeadlineOpen ? 'rotate-icon' : ''"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14" height="14"
                  fill="currentColor"
                  class="bi bi-chevron-down arrow-icon"
                  viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </p></b>
          </div>

          <transition name="slide">
            <div v-if="isDeadlineOpen" class="filter-options">
              <div v-for="(dd, index) in filterDeadlines" :key="index" class="form-check">
                <label class="form-check-label d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" :value="dd.id" v-model="selectedDeadlines"/>
                  <span class="ms-2">{{ dd.name }}</span>
                </label>
              </div>
            </div>
          </transition>

          <div class="justify-content-between filter-header mb-2 mt-3" @click="toggleFinancingValueFilter" href="#">
            <b><p class="mb-2 font-weight-bold d-flex justify-content-between align-items-center text-black">
              Размер финансирования
              <svg
                  :class="isFinancingValueOpen ? 'rotate-icon' : ''"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14" height="14"
                  fill="currentColor"
                  class="bi bi-chevron-down arrow-icon"
                  viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </p></b>
          </div>

          <transition name="slide">
            <div v-if="isFinancingValueOpen" class="filter-options">
              <div v-for="(fv, index) in filterFinancingValues" :key="index" class="form-check">
                <label class="form-check-label d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" :value="fv.id" v-model="selectedFinancingValues"/>
                  <span class="ms-2">{{ fv.name }}</span>
                </label>
              </div>
            </div>
          </transition>

          <div class="justify-content-between filter-header mb-2 mt-3" @click="toggleCompetitionTypesFilter" href="#">
            <b><p class="mb-2 font-weight-bold d-flex justify-content-between align-items-center text-black">
              Тип конкурса
              <svg
                  :class="isCompetitionOpen ? 'rotate-icon' : ''"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14" height="14"
                  fill="currentColor"
                  class="bi bi-chevron-down arrow-icon"
                  viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </p></b>
          </div>

          <transition name="slide">
            <div v-if="isCompetitionOpen" class="filter-options">
              <div v-for="(ct, index) in filterCompetitionTypes" :key="index" class="form-check">
                <label class="form-check-label d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" :value="ct.id" v-model="selectedCompetitionTypes"/>
                  <span class="ms-2">{{ ct.name }}</span>
                </label>
              </div>
            </div>
          </transition>
        </div>
        <button class="btn btn-link filter-clear text-start" @click="clearFilters">
          Очистить все фильтры
        </button>
      </div>


      <div class="col-md-9 cards-container">
        <ul class="list-unstyled">
          <li v-for="(grand, index) in filteredData" :key="index" class="grant-card mb-4 p-4 shadow-sm">
            <router-link class="grant-name" :to="{ name: 'HseGrandUser', params: { id: grand.id } }">
              <b class="grant-name">{{ grand.format_name }}</b>
            </router-link>
            <p class="grant-description">{{ grand.format_description }}</p>

            <p class="grant-field">
              <span class="grant-field-title">Тип исследования: </span>
              <span class="grant-field-list">{{ grand.type_of_researches.map(res => res.name).join(', ') }}</span>
            </p>

            <p class="grant-field">
              <span class="grant-field-title">Требования к результату: </span>
              <span class="grant-field-list">{{ grand.result_requirements.map(req => req.name).join(', ') }}</span>
            </p>

            <p class="grant-field">
              <span class="grant-field-title">Научные направления: </span>
              <span class="grant-field-list">{{ grand.science_branches.map(branch => branch.name).join(', ') }}</span>
            </p>

            <p class="grant-field">
              <span class="grant-field-title">Срок реализации: </span>
              <span class="grant-field-list">{{ grand.deadline_name }}</span>
            </p>

            <p class="grant-field">
              <span class="grant-field-title">Размер финансирования: </span>
              <span class="grant-field-list">{{ grand.financing.map(finv => finv.name).join(', ') }}</span>
            </p>

            <p class="grant-field">
              <span class="grant-field-title">Тип конкурса: </span>
              <span class="grant-field-list">{{ grand.competition_type_name }}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
  <div v-else>
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
  <div v-if="isPageLoaded">
    <footer-user-menu/>
  </div>
</template>



<script>
import http from "../../http-common";
import FooterUserMenu from "@/components/FooterUserMenu.vue";
import { getDataWithExpiration, saveDataWithTimestamp } from "@/savingFiltersInStorage";

export default {
  name: "ListHseGrandees",
  components: {
    FooterUserMenu
  },
  data() {
    return {
      hse_grandees: null,

      type_of_researches: [],
      filterValuesTypeOfResearch: [],
      isResearchTypeOpen: true,
      selectedResearchTypes: [],

      result_requirements: [],
      filterResultRequirements: [],
      isResultResuirementsOpen: false,
      selectedResultRequirements: [],

      science_branches: [],
      filterScienceBranches: [],
      isScienceBranchOpen: false,
      selectedScienceBranches: [],

      deadlines: [],
      filterDeadlines: [],
      isDeadlineOpen: false,
      selectedDeadlines: [],

      financingValues: [],
      filterFinancingValues: [],
      isFinancingValueOpen: false,
      selectedFinancingValues: [],

      competition_types: [],
      filterCompetitionTypes: [],
      isCompetitionOpen: false,
      selectedCompetitionTypes: [],

      financing: [],
      combined_data: [],
       groupedDataTR: {},
       groupedDataRR: {},
       groupedDataSB: {},
       groupedDataFV: {},
      isPageLoaded: false
    };
  },
  computed: {
    filteredData() {
      let filtered = this.combined_data;
      if (this.selectedResearchTypes.length > 0) {
        filtered = filtered.filter(grand => {
          return grand.type_of_researches.some(research =>
              this.selectedResearchTypes.includes(research.id)
          );
        });
      }
      if (this.selectedResultRequirements.length > 0) {
        filtered = filtered.filter(grand => {
          return grand.result_requirements.some(research =>
              this.selectedResultRequirements.includes(research.id)
          );
        });
      }
      if (this.selectedScienceBranches.length > 0) {
        filtered = filtered.filter(grand => {
          return grand.science_branches.some(research =>
              this.selectedScienceBranches.includes(research.id)
          );
        });
      }
      if (this.selectedDeadlines.length > 0) {
        filtered = filtered.filter(grand => {
          return this.selectedDeadlines.includes(grand.deadline_id);
        });
      }
      if (this.selectedFinancingValues.length > 0) {
        filtered = filtered.filter(grand => {
          return grand.financing.some(research =>
              this.selectedFinancingValues.includes(research.id)
          );
        });
      }
      if (this.selectedCompetitionTypes.length > 0) {
        filtered = filtered.filter(grand => {
          return this.selectedCompetitionTypes.includes(grand.competition_type_id);
        });
      }
      return filtered;
    }
  },
  methods: {
    async getHseGrandees() {
      http
          .get(`/hse_grandees_info`)
          .then(async response => {
            await response.data.forEach(a => {
              a.format_name = a.format_name.replace(/<[^>]*>/g, '');
              a.format_description = a.format_description.replace(/<[^>]*>/g, '');
            })
            response.data.sort((a,b) => b.id - a.id);
            this.combined_data = await response.data.map(grand => {
              return {
                ...grand,
                type_of_researches: this.groupedDataTR[grand.id] ? this.groupedDataTR[grand.id].type_of_researches : [],
                result_requirements: this.groupedDataRR[grand.id] ? this.groupedDataRR[grand.id].result_requirements : [],
                science_branches: this.groupedDataSB[grand.id] ? this.groupedDataSB[grand.id].science_branches : [],
                financing: this.groupedDataFV[grand.id] ? this.groupedDataFV[grand.id].financing : [],
              };
            });
            this.hse_grandees = response.data;
            this.isPageLoaded = true;
          })
          .catch(e => {
            console.log(e);
          });
    },
    async getTypeOfResearches(){
      try{
        const response = await http.get('/grandees_type_of_researches_info');
        response.data.forEach(item => {
          const { hse_grandees_id, type_of_research_id, type_of_research_name } = item;

          if (!this.groupedDataTR[hse_grandees_id]) {
            this.groupedDataTR[hse_grandees_id] = {
              hse_grandees_id,
              type_of_researches: []
            };
          }

          this.groupedDataTR[hse_grandees_id].type_of_researches.push({
            id: type_of_research_id,
            name: type_of_research_name
          });
        });
      }catch (e){
        console.log(e)
      }
    },
    async getResultRequirements(){
      try{
        const response = await http.get('/grandees_result_requirements_info');
        response.data.forEach(item => {
          const { hse_grandees_id, result_requirements_id, result_requirements_name } = item;

          if (!this.groupedDataRR[hse_grandees_id]) {
            this.groupedDataRR[hse_grandees_id] = {
              hse_grandees_id,
              result_requirements: []
            };
          }

          this.groupedDataRR[hse_grandees_id].result_requirements.push({
            id: result_requirements_id,
            name: result_requirements_name
          });
        });
      }catch (e){
        console.log(e)
      }
    },
    async getScienceBranches(){
      try{
        const response = await http.get('/grandees_science_branches_info');
        response.data.forEach(item => {
          const { hse_grandees_id, science_branch_id, science_branch_name } = item;

          if (!this.groupedDataSB[hse_grandees_id]) {
            this.groupedDataSB[hse_grandees_id] = {
              hse_grandees_id,
              science_branches: []
            };
          }

          this.groupedDataSB[hse_grandees_id].science_branches.push({
            id: science_branch_id,
            name: science_branch_name
          });
        });
      }catch (e){
        console.log(e)
      }
    },
    async getFinancing(){
      try{
        const response = await http.get('/financings_info');
        response.data.forEach(item => {
          const { hse_grandees_id, financing_values_id, financing_values_name } = item;

          if (!this.groupedDataFV[hse_grandees_id]) {
            this.groupedDataFV[hse_grandees_id] = {
              hse_grandees_id,
              financing: []
            };
          }

          this.groupedDataFV[hse_grandees_id].financing.push({
            id: financing_values_id,
            name: financing_values_name
          });
        });
      }catch (e){
        console.log(e)
      }
    },
    saveFilters() {
      const filters = {
        selectedResearchTypes: this.selectedResearchTypes,
        selectedResultRequirements: this.selectedResultRequirements,
        selectedScienceBranches: this.selectedScienceBranches,
        selectedDeadlines: this.selectedDeadlines,
        selectedFinancingValues: this.selectedFinancingValues,
        selectedCompetitionTypes: this.selectedCompetitionTypes
      };
      saveDataWithTimestamp('filters',filters);
    },
    loadFilters() {
      const savedFilters = getDataWithExpiration('filters',10);
      if (savedFilters) {
        this.selectedResearchTypes = savedFilters.selectedResearchTypes || [];
        this.selectedResultRequirements = savedFilters.selectedResultRequirements || [];
        this.selectedScienceBranches = savedFilters.selectedScienceBranches || [];
        this.selectedDeadlines = savedFilters.selectedDeadlines || [];
        this.selectedFinancingValues = savedFilters.selectedFinancingValues || [];
        this.selectedCompetitionTypes = savedFilters.selectedCompetitionTypes || [];
      }
    },
    async getFilterValues(){
      try{
        const responseTOR = await http.get('/type_of_researches');
        this.filterValuesTypeOfResearch = responseTOR.data;
        const responseRR = await http.get('/result_requirements');
        this.filterResultRequirements = responseRR.data;
        const responseSC = await http.get('/science_branches');
        this.filterScienceBranches = responseSC.data;
        const responseDD = await http.get('/deadlines');
        this.filterDeadlines = responseDD.data;
        const responseFV = await http.get('/financing_values');
        this.filterFinancingValues = responseFV.data;
        const responseCT = await http.get('/competition_types');
        this.filterCompetitionTypes = responseCT.data;
      }catch (e){
        console.log(e)
      }
    },
    clearFilters(){
      this.selectedScienceBranches = [];
      this.selectedDeadlines = [];
      this.selectedResearchTypes = [];
      this.selectedCompetitionTypes = [];
      this.selectedResultRequirements = [];
      this.selectedFinancingValues = [];
      this.saveFilters();
    },
    toggleResearchTypeFilter() {
      this.isResearchTypeOpen = !this.isResearchTypeOpen;
    },
    toggleResultRequirementsFilter(){
      this.isResultResuirementsOpen = !this.isResultResuirementsOpen;
    },
    toggleScienceBranchesFilter(){
      this.isScienceBranchOpen = !this.isScienceBranchOpen;
    },
    toggleDeadlinesFilter(){
      this.isDeadlineOpen = !this.isDeadlineOpen;
    },
    toggleFinancingValueFilter(){
      this.isFinancingValueOpen = !this.isFinancingValueOpen;
    },
    toggleCompetitionTypesFilter(){
      this.isCompetitionOpen = !this.isCompetitionOpen;
    }
  },
  watch: {
    selectedResearchTypes: 'saveFilters',
    selectedResultRequirements: 'saveFilters',
    selectedScienceBranches: 'saveFilters',
    selectedDeadlines: 'saveFilters',
    selectedFinancingValues: 'saveFilters',
    selectedCompetitionTypes: 'saveFilters'
  },
  async mounted() {
    this.loadFilters();
    await this.getTypeOfResearches();
    await this.getResultRequirements();
    await this.getScienceBranches();
    await this.getFinancing();
    await this.getHseGrandees();
    await this.getFilterValues();
  }
}
</script>

<style scoped>
.filter-clear{
  text-decoration: none;
  color: #102D69 !important;
  font-weight: bold !important;
  text-align: left !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/* Стили для стрелочек */
.arrow-icon {
  margin-left: auto;
  transition: transform 0.3s ease;
  transform: scale(1.2);
}

.rotate-icon {
  transform: rotate(180deg) scale(1.2);
}

/* Дополнительные стили */
.filter-header {
  font-size: 20px;
  cursor: pointer;
  text-align: left !important;

}
.form-check-label {
  display: flex;
  align-items: center;
  text-align: left; /* Выровнять текст по левому краю */
  margin-bottom: 5px; /* Отступ между элементами */
}

.filter-options {
  text-align: left; /* Выровнять весь блок фильтра по левому краю */
}

.form-check {
  margin-bottom: 5px;
}

.grant-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding-bottom: 10px;
  width: 100%;
}
.container-fluid {
  width: 90% !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
}

.row {
  display: flex !important;
}

.filters-container {
  flex: 0 0 25% !important; /* Фиксируем ширину для блока с фильтрами */
}

.cards-container {
  flex: 1 !important; /* Карточки занимают оставшееся пространство */
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.grant-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  word-wrap: break-word; /* Текст не выходит за пределы карточки */
  overflow: hidden;
  text-align: left; /* Выровнять текст по левой стороне */
  padding-bottom: 10px; /* Уменьшенный отступ внутри карточки */
  width: 100% !important; /* Карточка занимает всю ширину колонки */
}

.filters {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Стили для format_name */
.grant-name {
  text-decoration: none;
  font-weight: bold;
  font-size: 32px;
  text-align: left;
  margin-bottom: 5px; /* Уменьшенный отступ */
  word-wrap: break-word; /* Перенос текста на следующую строку */
  color: black;
  line-height: 1.2 !important;
}

/* Стили для format_description */
.grant-description {
  font-size: 16px;
  text-align: left;
  color: #6c757d;
  margin-bottom: 10px; /* Уменьшенный отступ */
  word-wrap: break-word; /* Перенос текста на следующую строку */
}

/* Отступ для кнопки "Очистить все фильтры" */
button.btn-link {
  margin-left: 8.5%; /* Отступ в 5% от текста "Фильтры" */
}

/* Стили для полей (Научные направления, Тип исследования и др.) */
.grant-field {
  margin-bottom: 5px; /* Уменьшенный отступ */
}

.grant-field-title {
  font-weight: bold;
  font-size: 16px;
  color: #003366;
  display: inline-block;
  margin-right: 5px; /* Небольшой отступ между заголовком и содержимым */
}

.grant-field-list {
  font-size: 16px;
  display: inline-block;
  color: #000;
  word-wrap: break-word;
  vertical-align: top;
}
</style>
