<template>
  <div class="navbar-container" style="min-width: 450px !important" v-cloak>
    <nav class="navbar navbar-light">
      <div class="container-fluid d-flex justify-content-start">
        <div class="d-flex align-items-start logo-text-container">
<a href="https://tes.hse.ru/" target="_blank">
  <img src="@/icons/01_Znak_CMYK.png" alt="HSE Logo" class="logo-hse">
</a>
          <div class="ms-3 text-container">
            <span class="navbar-text-main">Центр трансфера и управления<br> социально-экономической информацией</span>
          </div>
          <hr class="divider-custom"/>
          <div class="ms-0 text-container_2">
            <span class="navbar-text-secondary">Навигатор грантовой поддержки<br> НИУ ВШЭ и институтов развития</span>
          </div>
        </div>
      </div>
      <hr class="full-width-divider">
    </nav>
    <div v-if="showBanner" class="blue-banner">
      <div class="banner-header">
        <span class="banner-title">Навигатор грантовой поддержки – веб-сервис для эффективного поиска грантов НИУ ВШЭ и институтов развития, отвечающего запросам исследователей.</span>
        <button class="close-button m-lg-0" @click="closeBanner">×</button>
      </div>
      <p class="banner-description">Уточните критерии поиска с помощью фильтров для нахождения наилучших возможностей финансирования ваших исследований. Откройте новые горизонты для своих проектов!</p>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light" v-if="!isShowDetailed">
      <div class="container-fluid navigation-links-container">
        <div class="navbar-nav navigation-links d-flex justify-content-center">
          <div :class="{ 'active-link': isHseActive }">
            <router-link class="nav-link nav-link-fixed" to="/hseGrandees">Конкурсы и гранты НИУ ВШЭ</router-link>
          </div>
          <div class="nav-divider"></div>
          <div :class="{ 'active-link': isExternalActive }">
            <router-link class="nav-link nav-link-fixed" to="/externalGrandees">Внешние конкурсы и гранты</router-link>
          </div>
        </div>
      </div>
    </nav>

    <hr v-if="!isShowDetailed" class="partial-width-divider">
  </div>
</template>

<script>
export default {
  name: "NavBar",
  computed: {
    isHseActive() {
      return this.$route.path.includes('/hse');
    },
    isExternalActive() {
      return this.$route.path.includes('/external');
    },
    isShowDetailed(){
      return this.$route.path.includes('/hseGrand/') || this.$route.path.includes('/externalGrand/');
    }
  },
  data() {
    return {
      showBanner: !localStorage.getItem('bannerClosed')
    };
  },
  methods: {
    closeBanner() {
      this.showBanner = false;
      localStorage.setItem('bannerClosed', true);
    }
  }
};
</script>

<style scoped>

.blue-banner {
  background-color: #102D69 !important;
  color: white !important;
  padding: 20px 5% !important;
  position: relative !important;
  font-size: 16px !important;
  display: flex !important;
  flex-direction: column !important;
}

.banner-header {
  display: flex !important;
  justify-content: space-between !important;
  text-align: left !important;
}

.banner-title {
  text-align: left !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

.banner-description {
  text-align: left !important;
  margin-top: 10px !important;
  font-size: 24px !important;
}

.close-button {
  font-size: 16px !important;
  color: white !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  padding: 0 !important;
  text-decoration: none !important;
}

.close-button {
  font-size: 24px !important;
  color: white !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  padding: 0 !important;
}

/* Основной стиль для навигационной панели */
.navbar-container {
  margin-top: -60px !important;
  background-color: white !important;
}

.logo-hse {
  width: 50px !important;
  height: 50px !important;
}

.text-container {
  margin-left: 30px !important;
  text-align: left !important;
}
.text-container_2{
  text-align: left !important;
}

.navbar-text-main {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #002D6E !important;
  line-height: 1.0 !important;
  margin-bottom: 2px !important;
}

.navbar-text-secondary {
  font-size: 18px !important;
  color: #002D6E !important;
  margin-top: 0 !important;
  line-height: 1.0 !important;
}
.active-link .nav-link {
  color: #102D69 !important;
  font-weight: bold !important;
  text-decoration: none !important;
}
.nav-link {
  font-size: 32px !important;
  color: #003366 !important;
  padding-bottom: 5px !important;
  white-space: nowrap !important;
  transition: color 0.3s ease !important;
}

.nav-link-fixed {
  font-size: 32px !important;
  color: #555555 !important;
  text-align: center !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
}
.nav-divider {
  width: 1px !important;
  height: 40px !important; /* Высота разделителя */
  background-color: #000000 !important;
  margin: 0 25px !important; /* Отступ между линией и ссылками */
}

.nav-link:hover {
  color: #102D69 !important;
  text-decoration: none !important;
}
[v-cloak] {
  display: none !important; /* Скрывает элементы до полной загрузки Vue */
}


/* Линия на всю ширину страницы под первым элементом nav */
.full-width-divider {
  border: none !important;
  border-top: 1px solid #EAEAEA !important;
  width: 100% !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

/* Линия на 90% ширины страницы под вторым элементом nav */
.partial-width-divider {
  border: none !important;
  border-top: 2px solid #EAEAEA !important;
  width: 90% !important;
  margin: 10px auto 5px auto !important;
}

.navigation-links-container {
  width: 100% !important;
  display: flex !important;
  margin-left: 5% !important;
}

.navigation-links {
  align-items: center !important;
  gap: 20px !important; /* Отступ между ссылками и линией */
}

.divider-custom{
  width: 2px !important;
  background-color: #002D6E !important;
  border-color: #002D6E !important;
  margin: 0 25px !important;
  align-self: stretch !important;
}
@media(max-width: 500px){
  .nav-link, .nav-link-fixed{
    font-size: 24px !important;
  }
  .nav-divider{
    display: none !important;
  }
}
</style>
