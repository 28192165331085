<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список сроков реализации</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_DELETED}}
      </div>

      <ul class="list-group">
        <li v-for="(deadline, index) in deadlines" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="deadline.isEditing">
            <div class="input-group">
              <input v-model="deadline.name" class="form-control" placeholder="Название срока"/>
            </div>
            <div class="btn-group">
              <button @click="updateDeadline(deadline)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(deadline)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="deadline-name">{{ deadline.name }}</span>
            </div>
            <div class="btn-group">
              <button @click="editDeadline(deadline)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(deadline.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addDeadline" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.deadline.name" class="form-control" placeholder="Введите название срока реализации"/>
          <button type="submit" class="btn btn-primary ms-3">Добавить срок реализации</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import { isAuth } from "@/mixins/authCheck";

export default {
  name: "ListDeadline",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      deadlines: [],
      deadline: {
        name: ""
      }
    };
  },
  methods: {
    async getDeadline() {
      try {
        const response = await http.get(`/deadlines`);
        this.deadlines = response.data.map(deadline => ({
          ...deadline,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addDeadline(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.deadline.name
        };
        await http.post("/addDeadline", data);
        this.deadline = {
          name: ""
        };
        await this.getDeadline();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteDeadline(id);
      }
    },
    async deleteDeadline(id) {
      try {
        await http.post(`/deleteDeadline/${id}`);
        await this.getDeadline();
      } catch (e) {
        console.log(e);
      }
    },
    editDeadline(deadline) {
      deadline.isEditing = true;
    },
    async updateDeadline(deadline) {
      try {
        const data = {
          name: deadline.name
        };
        await http.post(`/updateDeadline/${deadline.id}`, data);
        deadline.isEditing = false;
        await this.getDeadline();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(deadline) {
      deadline.isEditing = false;
      this.getDeadline();
    }
  },
  mounted() {
    this.getDeadline();
    this.checkAuth();
  }
};
</script>
