<template>
  <admin-nav-bar />

  <div class="admin-content">
    <div class="d-flex justify-content-center mb-4">
      <router-link class="btn btn-primary" to="/admin/addHseGrand">Добавить</router-link>
    </div>
    <div class="card-grid">
      <div v-for="(hse_grand, index) in hse_grandees" :key="index" class="card">
        <div class="card-body">
          <h5 class="card-title">{{ hse_grand.format_name.replace(/<[^>]*>/g, '').substring(0,40) }}</h5>
          <p class="card-text">{{ hse_grand.format_description.replace(/<[^>]*>/g, '').substring(0,40) }}</p>
          <div class="card-actions">
            <router-link class="btn btn-primary" :to="{ name: 'hse_grand_detailed', params: { id: hse_grand.id } }">
              Редактировать
            </router-link>
            <button class="btn btn-danger" type="button" @click="confirmDelete(hse_grand.id)">Удалить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import { isAuth } from "@/mixins/authCheck";
import AdminNavBar from "@/components/AdminNavBar.vue";

export default {
  name: "ListHseGrandAdmin",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      hse_grandees: []
    };
  },
  methods: {
    getHseGrandees() {
      http
          .get("/hse_grandees")
          .then((response) => {
            this.hse_grandees = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
    },
    async deleteHseGrand(id) {
      try {
        await http.post(`/deleteHse_grand/${id}`);
        this.getHseGrandees();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteHseGrand(id);
      }
    }
  },
  mounted() {
    this.checkAuth();
    this.getHseGrandees();
  }
};
</script>
<style scoped>
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Карточки адаптируются под ширину экрана */
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #003366; /* Цвет брендбука */
}

.card-text {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #555;
}

.card-actions {
  display: flex;
  justify-content: space-between;
}

</style>
