<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список типов конкурсов</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_CLEARED}}
      </div>

      <ul class="list-group">
        <li v-for="(competitiontype, index) in competitiontypes" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="competitiontype.isEditing">
            <div class="input-group">
              <input v-model="competitiontype.name" class="form-control" placeholder="Название типа конкурса"/>
            </div>
            <div class="btn-group">
              <button @click="updateCompetitionType(competitiontype)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(competitiontype)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="competitiontype-name">{{ competitiontype.name }}</span>
            </div>
            <div class="btn-group">
              <button @click="editCompetitionType(competitiontype)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(competitiontype.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addCompetitionType" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.competitiontype.name" class="form-control" placeholder="Введите название типа конкурса"/>
          <button type="submit" class="btn btn-primary ms-3">Добавить тип конкурса</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import { isAuth } from "@/mixins/authCheck";

export default {
  name: "ListCompetitionType",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      competitiontypes: [],
      competitiontype: {
        name: ""
      }
    };
  },
  methods: {
    async getCompetitionTypes() {
      try {
        const response = await http.get(`/competition_types`);
        this.competitiontypes = response.data.map(competitiontype => ({
          ...competitiontype,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addCompetitionType(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.competitiontype.name
        };
        await http.post("/addCompetition_type", data);
        this.competitiontype = {
          name: ""
        };
        await this.getCompetitionTypes();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteCompetitionType(id);
      }
    },
    async deleteCompetitionType(id) {
      try {
        await http.post(`/deleteCompetition_type/${id}`);
        await this.getCompetitionTypes();
      } catch (e) {
        console.log(e);
      }
    },
    editCompetitionType(competitiontype) {
      competitiontype.isEditing = true;
    },
    async updateCompetitionType(competitiontype) {
      try {
        const data = {
          name: competitiontype.name
        };
        await http.post(`/updateCompetition_type/${competitiontype.id}`, data);
        competitiontype.isEditing = false;
        await this.getCompetitionTypes();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(competitiontype) {
      competitiontype.isEditing = false;
      this.getCompetitionTypes();
    }
  },
  mounted() {
    this.getCompetitionTypes();
    this.checkAuth();
  }
};
</script>
