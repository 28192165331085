<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-left">
        <p class="footer-text"><a style="text-decoration: none !important; color: #FFFFFF !important" href="https://tes.hse.ru/">Центр трансфера и управления социально-экономической информацией НИУ ВШЭ</a></p>
        <p class="footer-text"><a style="text-decoration: none !important; color: #FFFFFF !important" href="https://sezam.hse.ru/">Сезам - доступ к внешним научно-информационным ресурсам</a></p>
        <p class="footer-text"><a style="text-decoration: none !important; color: #FFFFFF !important" href="https://repo.hse.ru/">Репозиторий социально-экономической информации НИУ ВШЭ</a></p>
        <p class="footer-text">Обратная связь: <a href="mailto:jesda@hse.ru">jesda@hse.ru</a></p>
      </div>
      <div class="footer-right">
        <img src="@/icons/01_Logo_HSE_full_rus_PANTONE_for_dark_1.png" alt="Лого НИУ ВШЭ" class="footer-logo">
        <p class="footer-text">НИУ ВШЭ | ЦТУ СЭИ | 2024</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterUserMenu",
};
</script>


<style scoped>
.footer {
  margin-top: 40vh !important;
  background-color: #102D69 !important;
  color: white !important;
  padding: 20px 0 !important;
  border-top: 2px solid #EAEAEA !important;
  width: 100% !important;
}

.footer-content {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  max-width: 100% !important;
  padding: 0 5% !important;
}

.footer-left {
  text-align: left !important;
}

.footer-right {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.footer-logo {
  width: 80px !important;
  height: auto !important;
  margin-right: 10px !important;
}

.footer-text {
  font-size: 24px !important;
  color: white !important;
  margin-bottom: 5px !important;
}

</style>