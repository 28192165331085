<template>
  <admin-nav-bar/>
  <div class="admin-page" v-if="external_grand">
    <div class="admin-content">
      <h1>Обновить грант внешней организации</h1>
      <form @submit.prevent="updateExternalGrand">
    <div class="form-group card-shadow">
      <h3>Тип исследования</h3>
      <multiselect
          v-model="selectedResearchTypes"
          :options="type_of_researches"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Тип исследования"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeResearchType(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="typeOfResearchModal" class="btn btn-primary">Добавить</button>
      <add-type-of-research-modal v-show="isTypeOfResearchModalVisible" @close="closeTypeOfResearchModal"/>
    </div>

    <div class="form-group card-shadow">
      <h3>Требования к результату</h3>
      <multiselect
          v-model="selectedResult_requirements"
          :options="result_requirements"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Требования к результату"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeResultRequirements(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="resultRequirementsModal" class="btn btn-primary">Добавить</button>
      <add-result-requirements-modal v-show="isResultRequirementsModalVisible" @close="closeResultRequirementModal"/>
    </div>
    <div class="form-group card-shadow">
      <h3>Пояснения к результату (не выводится в карточке)</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorRelevanceCommentReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Актуальность</h3>
      <input class="form-check-input ms-2" type="checkbox" v-model="external_grand.relevance">
    </div>
    <div class="form-group card-shadow">
      <h3>Регулярность</h3>
      <multiselect
          v-model="external_grand.regularity_id"
          :options="regularities"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Регулярность"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeRegularity(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
        <template v-slot:afterList>
          <button type="button" @click="getRegularities">{{isAllRegularitiesShowed ? 'Показать все значения' : 'Показать значения по умолчанию' }}</button>
        </template>
      </multiselect>
      <button type="button" @click="regularitiesModal" class="btn btn-primary">Добавить</button>
      <add-regularities-modal v-show="isRegularitiesModalVisible" @close="closeRegularitiesModal"/>

    </div>

    <div class="form-group card-shadow">
      <h3>Периодичность</h3>
      <multiselect
          v-model="external_grand.period_id"
          :options="periods"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Периодичность"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removePeriod(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
        <template v-slot:afterList>
          <button type="button" @click="getPeriods">{{isAllPeriodsShowed ? 'Показать все значения' : 'Показать значения по умолчанию' }}</button>
        </template>
      </multiselect>
      <button type="button" @click="periodsModal" class="btn btn-primary">Добавить</button>
      <add-periods-modal v-show="isPeriodsModalVisible" @close="closePeriodsModal"/>
    </div>

    <div class="form-group card-shadow">
      <h3>Наименование</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorOrganizationReady"
      />
    </div>

    <div class="form-group card-shadow">
      <h3>Описание конкурса</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorDescriptionReady"
      />
    </div>

    <div class="form-group card-shadow">
      <h3>Формы и виды поддержки фонда</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorSupportReady"
      />
    </div>

    <div class="form-group card-shadow">
      <h3>Отрасли науки</h3>
      <multiselect
          v-model="selectedScienceBranches"
          :options="science_branches"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Отрасли науки"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeScienceBranch(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="scienceBranchModal" class="btn btn-primary">Добавить</button>
      <add-science-branch-modal v-show="isScienceBranchModalVisible" @close="closeScienceBranchModal"/>
    </div>

    <div class="form-group card-shadow">
      <h3>Конкурсные треки</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorTopicReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Страница конкурса</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorWebsiteReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Положение/Регламент</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorRegulationsReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Требования к участникам</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorParticipantRequirementsReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Кто отбирает проекты (не отображается в карточке)</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorJuryReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Срок реализации</h3>
      <multiselect
          v-model="external_grand.deadline_id"
          :options="deadlines"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Срок реализации"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeDeadline(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="deadlineModal" class="btn btn-primary">Добавить</button>
      <add-deadline-modal v-show="isDeadlineModalVisible" @close="closeDeadlineModal"/>
    </div>

    <div class="form-group card-shadow">
      <h3>Сроки реализации проекта</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorDeadlineCommentReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Размер финансирования</h3>
      <multiselect
          v-model="selectedFinancingValues"
          :options="financing_values"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Размер финансирования"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeFinancingValue(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="financingValuesModal" class="btn btn-primary">Добавить</button>
      <add-financing-value-modal v-show="isFinancingValuesModalVisible" @close="closeFinancingValuesModal"/>
    </div>
    <div class="form-group card-shadow">
      <h3>Условия и размер финансирования</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorFinancingCommentReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Прекращение или сокращения финансирование</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorFundingReductionReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Критерии оценки заявок</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorCriteriaReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Федеральный округ</h3>
      <multiselect
          v-model="external_grand.federal_district_id"
          :options="federal_districts"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Федеральный округ"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeFederalDistrict(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="federalDistrictModal" class="btn btn-primary">Добавить</button>
      <add-federal-district-modal v-show="isFederalDistrictModalVisible" @close="closeFederalDistrictModal"/>
    </div>

    <div class="form-group card-shadow">
      <h3>Регион</h3>
      <multiselect
          v-model="external_grand.region_id"
          :options="regions"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Регион"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeRegion(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="regionModal" class="btn btn-primary">Добавить</button>
      <add-region-modal v-show="isRegionModalVisible" @close="closeRegionModal"/>
    </div>

    <div class="form-group card-shadow">
      <h3>Город</h3>
      <multiselect
          v-model="external_grand.city_id"
          :options="cities"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Город"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeCity(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="cityModal" class="btn btn-primary">Добавить</button>
      <add-city-modal v-show="isCitiesModalVisible" @close="closeCityModal"/>
    </div>

    <div class="form-group card-shadow">
      <h3>Адрес</h3>
      <input class="form-control" placeholder="Введите адрес" type="text" v-model="external_grand.address">
    </div>

    <div class="form-group card-shadow">
      <h3>Телефон</h3>
      <input class="form-control" placeholder="Введите телефон" type="text" v-model="external_grand.phone_number">
    </div>

    <div class="form-group card-shadow">
      <h3>Факс</h3>
      <input class="form-control" placeholder="Введите факс" type="text" v-model="external_grand.fax">
    </div>

    <div class="form-group card-shadow">
      <h3>Электронная почта</h3>
      <input class="form-control" placeholder="Введите почту" type="text" v-model="external_grand.email">
    </div>

    <div class="form-group card-shadow">
      <h3>Сайт в интернете</h3>
      <input class="form-control" placeholder="Введите сайт" type="text" v-model="external_grand.website">
    </div>

    <div class="form-group card-shadow">
      <h3>Сфера деятельности организации</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorActivityAreaReady"
      />
    </div>

    <div class="form-group card-shadow">
      <h3>Приоритетные направления</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorPriorityAreaReady"
      />
    </div>

    <div class="form-group card-shadow">
      <h3>Год создания</h3>
      <input class="form-control" placeholder="Введите год создания" type="text" v-model="external_grand.foundation_year">
    </div>

    <button type="submit" class="btn btn-primary">Обновить грант</button>
      </form>
  </div>
  </div>
  <div v-else>
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import Multiselect from "vue-multiselect";
import {quillEditor}  from "vue3-quill";
import addTypeOfResearchModal from "@/components/modals/addTypeOfResearchModal.vue";
import addResultRequirementsModal from "@/components/modals/addResultRequirementsModal.vue";
import addRegularitiesModal from "@/components/modals/addRegularitiesModal.vue";
import addPeriodsModal from "@/components/modals/addPeriodsModal.vue";
import addDeadlineModal from "@/components/modals/addDeadlineModal.vue";
import addScienceBranchModal from "@/components/modals/addScienceBranchModal.vue";
import addFederalDistrictModal from "@/components/modals/addFederalDistrictModal.vue";
import addRegionModal from "@/components/modals/addRegionModal.vue";
import addCityModal from "@/components/modals/addCityModal.vue";
import AdminNavBar from "@/components/AdminNavBar.vue";
import {isAuth} from "@/mixins/authCheck";
import AddFinancingValueModal from "@/components/modals/addFinancingValueModal.vue";

export default {
  name: "external-grand-detailed",
  props: ['id'],
  components: {
    AddFinancingValueModal,
    AdminNavBar,
    Multiselect,
    quillEditor,
    addResultRequirementsModal,
    addTypeOfResearchModal,
    addRegularitiesModal,
    addPeriodsModal,
    addDeadlineModal,
    addScienceBranchModal,
    addFederalDistrictModal,
    addRegionModal,
    addCityModal,
  },
  mixins: [isAuth],
  data() {
    return {
      external_grand: null,
      federal_districts: [],
      regions: [],
      cities: [],
      isCitiesModalVisible: false,
      type_of_researches: [],
      selectedResearchTypes: [],
      isTypeOfResearchModalVisible: false,
      financing_values: [],
      selectedFinancingValues: [],
      isFinancingValuesModalVisible: false,
      result_requirements: [],
      selectedResult_requirements: [],
      isResultRequirementsModalVisible: false,
      quillInstanceRelevance: null,
      regularities:[],
      isAllRegularitiesShowed: false,
      isRegularitiesModalVisible: false,
      isDeadlineCommentModalVisible: false,
      periods: [],
      isAllPeriodsShowed: false,
      isPeriodsModalVisible: false,
      competition_types: [],
      isFederalDistrictModalVisible: false,
      isRegionModalVisible: false,
      isCompetitionTypesModalVisible: false,
      quillInstanceFormatName: null,
      quillInstanceFormatDescription: null,
      quillInstanceFinancingComment: null,
      quillInstanceFormatWebsite: null,
      quillInstanceCriteria: null,
      quillIntanceSupport: null,
      quillInstanceTopic: null,
      quillInstancePriorityArea: null,
      quillInstableWebsite: null,
      quillIntanceDescription: null,
      qullInstanceRegulations: null,
      quillInstanceActivityField: null,
      quillInstanceRelevanceComment: null,
      quillInstanceApplies: null,
      quillInstanceJury: null,
      quillInstanceOrganization: null,
      deadlines: [],
      isDeadlineModalVisible: false,
      quillInstanceDeadlineComment: null,
      quillInstanceParticipantRequirements: null,
      quillInstanceFundingReduction: null,
      quillInstanceAffilation: null,
      quillInstanceArea: null,
      science_branches: [],
      selectedScienceBranches: [],
      isScienceBranchModalVisible: false,
      editorOptions: {
        theme: 'snow',
        placeholder: 'Введите текст'
      },
      afterSelectedResearchTypes: [],
      afterSelectedScienceBranchs: [],
      afterSelectedResultRequirements: [],
      afterSelectedFinancingValues: [],
      afterSelectedPersonEmails: [],
    };
  },
  methods: {
    async updateExternalGrand(){
      try{
        const htmlResReq = this.quillInstanceRelevanceComment.root.innerHTML;
        const htmlOrganization = this.quillInstanceOrganization.root.innerHTML;
        const htmlDescription = this.quillIntanceDescription.root.innerHTML;
        const htmlSupport = this.quillIntanceSupport.root.innerHTML;
        const htmlTopic = this.quillInstanceTopic.root.innerHTML;
        const htmlWebsiteInfo = this.quillInstableWebsite.root.innerHTML;
        const htmlRegulations = this.qullInstanceRegulations.root.innerHTML;
        const htmlParticipantRequirements = this.quillInstanceParticipantRequirements.root.innerHTML;
        const htmlJury = this.quillInstanceJury.root.innerHTML;
        const htmlFundintReduction = this.quillInstanceFundingReduction.root.innerHTML;
        const htmlCriteria = this.quillInstanceCriteria.root.innerHTML;
        const htmlActivityField = this.quillInstanceActivityField.root.innerHTML;
        const htmlPriorityArea = this.quillInstancePriorityArea.root.innerHTML;
        const htmlDeadlineComment = this.quillInstanceDeadlineComment.root.innerHTML;
        const htmlFinancingComment = this.quillInstanceFinancingComment.root.innerHTML;
        var data = {
          result_requirements_comment: htmlResReq,
          relevance: this.external_grand.relevance,
          regularity_id: this.external_grand.regularity_id.id,
          period_id: this.external_grand.period_id.id,
          organization: htmlOrganization,
          description: htmlDescription,
          support: htmlSupport,
          topic: htmlTopic,
          website_info: htmlWebsiteInfo,
          regulations: htmlRegulations,
          member_requirements: htmlParticipantRequirements,
          jury: htmlJury,
          deadline_id: this.external_grand.deadline_id.id,
          deadline_comment: htmlDeadlineComment,
          financing_comment: htmlFinancingComment,
          funding_reduction: htmlFundintReduction,
          criteria: htmlCriteria,
          federal_district_id: this.external_grand.federal_district_id.id,
          region_id: this.external_grand.region_id.id,
          city_id: this.external_grand.city_id.id,
          address: this.external_grand.address,
          phone_number: this.external_grand.phone_number,
          fax: this.external_grand.fax,
          email: this.external_grand.email,
          website: this.external_grand.website,
          activity_field: htmlActivityField,
          priority_area: htmlPriorityArea,
          foundation_year: this.external_grand.foundation_year,
        }
        await http.post(`/updateExternal_grand/${this.id}`,data);
        for(var rtToAdd in this.selectedResearchTypes){
          if(!this.afterSelectedResearchTypes.some(item => item.id === this.selectedResearchTypes[rtToAdd]?.id)){
            const data = {
              hse_grandees_id: null,
              external_grandees_id: this.id,
              type_of_research_id: this.selectedResearchTypes[rtToAdd].id
            }
            await http.post('/addGrandees_type_of_research',data);
          }
        }
        for(var rtToDelete in this.afterSelectedResearchTypes){
          if(!this.selectedResearchTypes.some(item => item.id === this.afterSelectedResearchTypes[rtToDelete]?.id)){
            await http.post(`/deleteGrandees_type_of_research/externalId=${this.id}/type_of_research_id=${this.afterSelectedResearchTypes[rtToDelete].id}`);
          }
        }

        for(var fvToAdd in this.selectedFinancingValues){
          if(!this.afterSelectedFinancingValues.some(item => item.id === this.selectedFinancingValues[fvToAdd]?.id)){
            const data = {
              hse_grandees_id: null,
              external_grandees_id: this.id,
              financing_values_id: this.selectedFinancingValues[fvToAdd].id
            }
            await http.post('/addFinancing',data);
          }
        }
        for(var fvToDelete in this.afterSelectedFinancingValues){
          if(!this.selectedFinancingValues.some(item => item.id === this.afterSelectedFinancingValues[fvToDelete]?.id)){
            await http.post(`/deleteFinancing/externalId=${this.id}/financing_values_id=${this.afterSelectedFinancingValues[fvToDelete].id}`)
          }
        }

        for(var sbToAdd in this.selectedScienceBranches){
          if(!this.afterSelectedScienceBranchs.some(item => item.id === this.selectedScienceBranches[sbToAdd]?.id)){
            const data = {
              hse_grandees_id: null,
              external_grandees_id: this.id,
              science_branch_id: this.selectedScienceBranches[sbToAdd].id
            }
            await http.post('/addGrandees_science_branch',data);
          }
        }
        for(var sbToDelete in this.afterSelectedScienceBranchs){
          if(!this.selectedScienceBranches.some(item => item.id === this.afterSelectedScienceBranchs[sbToDelete]?.id)){
            await http.post(`/deleteGrandees_science_branch/externalId=${this.id}/science_branch_id=${this.afterSelectedScienceBranchs[sbToDelete].id}`);
          }
        }
        for(var rrToAdd in this.selectedResult_requirements){
          if(!this.afterSelectedResultRequirements.some(item => item.id === this.selectedResult_requirements[rrToAdd]?.id)){
            const data = {
              hse_grandees_id: null,
              external_grandees_id: this.id,
              result_requirements_id: this.selectedResult_requirements[rrToAdd].id
            }
            await http.post('/addGrandees_result_requirement',data);
          }
        }

        for(var rrToDelete in this.afterSelectedResultRequirements){
          if(!this.selectedResult_requirements.some(item => item.id === this.afterSelectedResultRequirements[rrToDelete]?.id)){
            await http.post(`/deleteGrandees_result_requirement/externalId=${this.id}/result_requirement=${this.afterSelectedResultRequirements[rrToDelete].id}`);
          }
        }
        console.log('updated')
        window.location.href = '/admin/externalGrandees';
      }catch (e){
        console.log(e)
      }
    },
    async getExternalGrand(){
      try{
        const response = await http.get(`/external_grand/${this.id}`);
        this.external_grand = await response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getTypeOfResearches() {
      try {
        const response = await http.get("/type_of_researches");
        this.type_of_researches = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getResultRequirements(){
      try{
        const response = await http.get("/result_requirements");
        this.result_requirements = response.data;
      }catch(e){
        console.log(e);
      }
    },
    async getFinancingValues(){
      try{
        const response = await http.get('/financing_values');
        this.financing_values = await response.data;
      }catch (e){
        console.log(e)
      }
    },
    async removeFinancingValue(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteFinancing_value/${id}`);
        const response = await http.get('/financing_values');
        this.financing_values = await response.data;
      }catch (e){
        console.log(e)
      }
      }
    },
    financingValuesModal(){
      this.isFinancingValuesModalVisible = true;
    },
    async closeFinancingValuesModal(){
      this.isFinancingValuesModalVisible = false;
      await this.getFinancingValues();
    },
    async getRegularities(){
      try{
        var response;
        if(this.isAllRegularitiesShowed){
          response = await http.get("/regularities");
          this.isAllRegularitiesShowed = false;
        }else {
          response = await http.get('/regularities/defaultValues');
          this.isAllRegularitiesShowed = true;
        }
        this.regularities = response.data;
      }catch(e){
        console.log(e);
      }
    },
    async removeRegularity(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteRegularity/${id}`);
        const response = await http.get('/regularities');
        this.result_requirements = response.data;
      }catch(e){
        console.log(e)
      }
      }
    },
    async getRegions(){
      try{
        const response = await http.get('/regions');
        this.regions = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async removeRegion(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteRegion/${id}`);
        await this.getRegions();
      }catch (e){
        console.log(e)
      }
      }
    },
    async getCities(){
      try{
        const response = await http.get('/cities');
        this.cities = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async removeCity(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteCity/${id}`);
        await this.getCities();
      }catch (e){
        console.log(e)
      }
      }
    },
    async removeFederalDistrict(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteFederal_district/${id}`)
        await this.getFederalDistricts();
      }catch (e){
        console.log(e)
      }
      }
    },
    async getPeriods(){
      try{
        var response;
        if(this.isAllPeriodsShowed){
          response = await http.get("/periods");
          this.isAllPeriodsShowed = false;
        }else {
          response = await http.get('/periods/defaultValues');
          this.isAllPeriodsShowed = true;
        }
        this.periods = response.data;
      }catch(e){
        console.log(e);
      }
    },
    async getCompetitionTypes(){
      try{
        const response = await http.get('/competition_types');
        this.competition_types = response.data;
      }catch (e){
        console.log(e);
      }
    },
    async getDeadlines(){
      try{
        const response = await http.get('/deadlines');
        this.deadlines = response.data;
      }catch (e){
        console.log(e);
      }
    },
    async getScienceBranches(){
      try{
        const response = await http.get('/science_branches');
        this.science_branches = response.data;
      }catch (e){
        console.log(e);
      }
    },
    async getFederalDistricts(){
      try{
        const response = await http.get('/federal_districts');
        this.federal_districts = response.data;
      }catch (e){
        console.log(e)
      }
    },
    typeOfResearchModal(){
      this.isTypeOfResearchModalVisible = true;
    },
    async closeTypeOfResearchModal(){
      this.isTypeOfResearchModalVisible = false;
      await this.getTypeOfResearches();
    },
    cityModal(){
      this.isCitiesModalVisible = true;
    },
    async closeCityModal(){
      this.isCitiesModalVisible = false;
      await this.getCities();
    },
    regionModal(){
      this.isRegionModalVisible = true;
    },
    async closeRegionModal(){
      this.isRegionModalVisible = false;
      await this.getRegions();
    },
    async removeResearchType(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteType_of_research/${id}`)
        await this.getTypeOfResearches();
      }catch(e){
        console.log(e)
      }
      }
    },
    async removeResultRequirements(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {      
      try{
        await http.post(`/deleteResult_requirement/${id}`);
        await this.getResultRequirements();
      }catch(e){
        console.log(e)
      }
      }
    },
    resultRequirementsModal(){
      this.isResultRequirementsModalVisible = true;
    },
    async closeResultRequirementModal(){
      this.isResultRequirementsModalVisible = false;
      await this.getResultRequirements();
    },
    federalDistrictModal(){
      this.isFederalDistrictModalVisible = true;
    },
    async closeFederalDistrictModal(){
      this.isFederalDistrictModalVisible = false;
      await this.getFederalDistricts();
    },
    regularitiesModal(){
      this.isRegularitiesModalVisible = true;
    },
    async closeRegularitiesModal(){
      this.isRegularitiesModalVisible = false;
      await this.getRegularities();
    },
    async removePeriod(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deletePeriod/${id}`);
        const response = await http.get('/periods');
        this.periods = response.data;
      }catch(e){
        console.log(e)
      }
      }
    },
    periodsModal(){
      this.isPeriodsModalVisible = true;
    },
    async closePeriodsModal(){
      this.isPeriodsModalVisible = false;
      await this.getPeriods();
    },
    onEditorDescriptionReady(quill){
      this.quillIntanceDescription = quill;
      if(this.external_grand.description){
        this.quillIntanceDescription.root.innerHTML = this.external_grand.description;
      }
    },
    onEditorRelevanceCommentReady(quill){
      this.quillInstanceRelevanceComment = quill
      if(this.external_grand.result_requirements_comment){
        this.quillInstanceRelevanceComment.root.innerHTML = this.external_grand.result_requirements_comment;
      }
    },
    onEditorFinancingCommentReady(quill){
      this.quillInstanceFinancingComment = quill;
      if(this.external_grand.financing_comment){
        this.quillInstanceFinancingComment.root.innerHTML = this.external_grand.financing_comment;
      }
    },
    onEditorDeadlineCommentReady(quill){
      this.quillInstanceDeadlineComment = quill
      if(this.external_grand.deadline_comment){
        this.quillInstanceDeadlineComment.root.innerHTML = this.external_grand.deadline_comment;
      }
    },
    onEditorPriorityAreaReady(quill){
      this.quillInstancePriorityArea = quill
      if(this.external_grand.priority_area){
        this.quillInstancePriorityArea.root.innerHTML = this.external_grand.priority_area;
      }
    },
    onEditorActivityAreaReady(quill){
      this.quillInstanceActivityField = quill;
      if(this.external_grand.activity_field){
        this.quillInstanceActivityField.root.innerHTML = this.external_grand.activity_field;
      }
    },
    onEditorCriteriaReady(quill){
      this.quillInstanceCriteria = quill;
      if(this.external_grand.criteria){
        this.quillInstanceCriteria.root.innerHTML = this.external_grand.criteria;
      }
    },
    onEditorTopicReady(quill){
      this.quillInstanceTopic = quill;
      if(this.external_grand.topic){
        this.quillInstanceTopic.root.innerHTML = this.external_grand.topic;
      }
    },
    onEditorWebsiteReady(quill){
      this.quillInstableWebsite = quill;
      if(this.external_grand.website_info){
        this.quillInstableWebsite.root.innerHTML = this.external_grand.website_info;
      }
    },
    onEditorRegulationsReady(quill){
      this.qullInstanceRegulations = quill;
      if(this.external_grand.regulations){
        this.qullInstanceRegulations.root.innerHTML = this.external_grand.regulations;
      }
    },
    onEditorSupportReady(quill) {
      this.quillIntanceSupport = quill;
      if(this.external_grand.support){
        this.quillIntanceSupport.root.innerHTML = this.external_grand.support;
      }
    },
    onEditorJuryReady(quill) {
      this.quillInstanceJury = quill;
      if(this.external_grand.jury){
        this.quillInstanceJury.root.innerHTML = this.external_grand.jury;
      }
    },
    onEditorOrganizationReady(quill) {
      this.quillInstanceOrganization = quill;
      if(this.external_grand.organization){
        this.quillInstanceOrganization.root.innerHTML = this.external_grand.organization;
      }
    },
    deadlineModal() {
      this.isDeadlineModalVisible = true;
    },
    async closeDeadlineModal() {
      this.isDeadlineModalVisible = false;
      await this.getDeadlines();
    },
    async removeDeadline(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      await http.post(`/deleteDeadline/${id}`)
      await this.getDeadlines();
      }
    },
    onEditorParticipantRequirementsReady(quill) {
      this.quillInstanceParticipantRequirements = quill;
      if(this.external_grand.member_requirements){
        this.quillInstanceParticipantRequirements.root.innerHTML = this.external_grand.member_requirements;
      }
    },
    onEditorFundingReductionReady(quill) {
      this.quillInstanceFundingReduction = quill;
      if(this.external_grand.funding_reduction){
        this.quillInstanceFundingReduction.root.innerHTML = this.external_grand.funding_reduction;
      }
    },
    async removeScienceBranch(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try {
        await http.post(`/deleteScience_branch/${id}`);
        await this.getScienceBranches();
      } catch (e) {
        console.log(e);
      }
      }
    },
    scienceBranchModal() {
      this.isScienceBranchModalVisible = true;
    },
    async closeScienceBranchModal() {
      this.isScienceBranchModalVisible = false;
      await this.getScienceBranches();
    },
    async getSelectedValues(){
      try{
        const torValues = await http.get(`/grandees_type_of_researches/byExternalId/${this.id}`);
        this.selectedResearchTypes = torValues.data;
        this.afterSelectedResearchTypes = JSON.parse(JSON.stringify(torValues.data));
        const fvValues = await http.get(`/financing/byExternalId/${this.id}`);
        this.selectedFinancingValues = fvValues.data;
        this.afterSelectedFinancingValues = JSON.parse(JSON.stringify(fvValues.data));
        const rrValues = await http.get(`/grandees_result_requirements/byExternalId/${this.id}`);
        this.selectedResult_requirements = rrValues.data;
        this.afterSelectedResultRequirements = JSON.parse(JSON.stringify(rrValues.data));
        const sbValues = await http.get(`/grandees_science_branches/byExternalId/${this.id}`);
        this.selectedScienceBranches = sbValues.data;
        this.afterSelectedScienceBranchs = JSON.parse(JSON.stringify(sbValues.data));
        const regularityValue = await http.get(`/regularity/${this.external_grand.regularity_id}`);
        this.external_grand.regularity_id = regularityValue.data;
        const periodValue = await http.get(`/period/${this.external_grand.period_id}`);
        this.external_grand.period_id = periodValue.data;
        const deadlineValue = await http.get(`/deadline/${this.external_grand.deadline_id}`);
        this.external_grand.deadline_id = deadlineValue.data;
        const federalDistrictValue = await http.get(`/federal_district/${this.external_grand.federal_district_id}`);
        this.external_grand.federal_district_id = federalDistrictValue.data;
        const regionValue = await http.get(`/region/${this.external_grand.region_id}`);
        this.external_grand.region_id = regionValue.data;
        const cityValue = await http.get(`/city/${this.external_grand.city_id}`);
        this.external_grand.city_id = cityValue.data;
      }catch (e){
        console.log(e)
      }
    }
  },
  async mounted() {
    await this.checkAuth();
    await this.getExternalGrand();
    await this.getTypeOfResearches();
    await this.getFederalDistricts();
    await this.getResultRequirements();
    await this.getRegularities();
    await this.getPeriods();
    await this.getFinancingValues();
    await this.getCompetitionTypes();
    await this.getDeadlines();
    await this.getScienceBranches();
    await this.getRegions();
    await this.getCities();
    await this.getSelectedValues();
  }
};
</script>

<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "../../../node_modules/quill/dist/quill.snow.css";

.custom-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-right: 30px; /* Для отступа под крестик */
}

.delete-button {
  font-size: 24px; /* Сделаем крестик больше */
  color: rgba(231, 38, 38, 0.5); /* Полупрозрачный */
  position: absolute;
  right: 10px; /* Отступ от правого края */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: auto; /* Оставляем возможность клика на кнопку */
  background: transparent; /* Убираем фон кнопки */
  border: 10px; /* Убираем границу */
  z-index: 10; /* Поверх всего */
  cursor: pointer;
}

.delete-button:hover {
  color: rgba(0, 0, 0, 0.7); /* Добавим небольшой эффект при наведении */
}

.test {
  outline-style: dotted;
  margin: 5px;
}

#quill-editor {
  height: 1000px;
}
.form-control {
  word-wrap: break-word!important;
  word-break: break-all !important;
}
</style>
