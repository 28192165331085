<template>
  <div class="admin-page">
  <admin-nav-bar/>
    <div class="admin-content">
      <h1>Добавить грант ВШЭ</h1>
  <form @submit="addHseGrand">
    <div class="form-group card-shadow">
      <h3>Тип исследования</h3>
    <multiselect
        v-model="selectedResearchTypes"
        :options="type_of_researches"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Тип исследования"
        label="name"
        track-by="id"
        :searchable="true"
    >
      <template v-slot:option="{ option}">
        <div class="custom-option">
          {{ option.name }}
          <button
              class="delete-button"
              @click.stop.prevent="removeResearchType(option.id)"
              aria-label="Delete"
          >
            &times;
          </button>
        </div>
      </template>
    </multiselect>
      <button type="button" @click="typeOfResearchModal" class="btn btn-primary">Добавить</button>
      <add-type-of-research-modal v-show="isTypeOfResearchModalVisible" @close="closeTypeOfResearchModal"/>
    </div>

    <div class="form-group card-shadow">
      <h3>Требования к результату</h3>
    <multiselect
        v-model="selectedResult_requirements"
        :options="result_requirements"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Требования к результату"
        label="name"
        track-by="id"
        :searchable="true"
    >
      <template v-slot:option="{ option}">
        <div class="custom-option">
          {{ option.name }}
          <button
              class="delete-button"
              @click.stop.prevent="removeResultRequirements(option.id)"
              aria-label="Delete"
          >
            &times;
          </button>
        </div>
      </template>
    </multiselect>
      <button  type="button" @click="resultRequirementsModal" class="btn btn-primary">Добавить</button>
      <add-result-requirements-modal v-show="isResultRequirementsModalVisible" @close="closeResultRequirementModal"/>
    </div>
    <div class="form-group card-shadow">
        <h3>Актуальность</h3>
        <quill-editor
            class="form-control form-control-dark"
            :options="editorOptions"
            @ready="onEditorRelevanceReady"
        />
    </div>
  <div class="form-group card-shadow">
    <h3>Регулярность</h3>
    <multiselect
        v-model="hse_grand.regularity_id"
        :options="regularities"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Регулярность"
        label="name"
        track-by="id"
        :searchable="true"
    >
      <template v-slot:option="{ option}">
        <div class="custom-option">
          {{ option.name }}
          <button
              class="delete-button"
              @click.stop.prevent="removeRegularity(option.id)"
              aria-label="Delete"
          >
            &times;
          </button>
        </div>
      </template>
      <template v-slot:afterList>
        <button class="btn btn-primary" type="button" @click="getRegularities">{{isAllRegularitiesShowed ? 'Показать все значения' : 'Показать значения по умолчанию' }}</button>
      </template>
    </multiselect>
    <button type="button" @click="regularitiesModal" class="btn btn-primary">Добавить</button>
    <add-regularities-modal v-show="isRegularitiesModalVisible" @close="closeRegularitiesModal"/>

  </div>

  <div class="form-group card-shadow">
    <h3>Периодичность</h3>
    <multiselect
        v-model="hse_grand.period_id"
        :options="periods"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Периодичность"
        label="name"
        track-by="id"
        :searchable="true"
    >
      <template v-slot:option="{ option}">
        <div class="custom-option">
          {{ option.name }}
          <button
              class="delete-button"
              @click.stop.prevent="removePeriod(option.id)"
              aria-label="Delete"
          >
            &times;
          </button>
        </div>
      </template>
      <template v-slot:afterList>
        <button class="btn btn-primary" type="button" @click="getPeriods">{{isAllPeriodsShowed ? 'Показать все значения' : 'Показать значения по умолчанию' }}</button>
      </template>
    </multiselect>
    <button type="button" @click="periodsModal" class="btn btn-primary">Добавить</button>
    <add-periods-modal v-show="isPeriodsModalVisible" @close="closePeriodsModal"/>
  </div>
    <div class="form-group card-shadow">
      <h3>Тип конкурса</h3>
    <multiselect
        v-model="hse_grand.competition_type_id"
        :options="competition_types"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Тип конкурса"
        label="name"
        track-by="id"
        :searchable="true"
    >
      <template v-slot:option="{ option}">
        <div class="custom-option">
          {{ option.name }}
          <button
              class="delete-button"
              @click.stop.prevent="removeCompetitionType(option.id)"
              aria-label="Delete"
          >
            &times;
          </button>
        </div>
      </template>
    </multiselect>
    <button type="button" @click="competitionTypeModal" class="btn btn-primary">Добавить</button>
    <add-competition-type-modal v-show="isCompetitionTypesModalVisible" @close="closeCompetitionTypeModal"/>
    </div>
    <div class="form-group card-shadow">
      <h3>Наименование</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorFormatNameReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Описание</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorFormatDescriptionReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Страница конкурса</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorFormatWebsiteReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Положение и регламенты</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorRegulationsReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Контакты</h3>
      <multiselect
          v-model="selectedPersonEmails"
          :options="person_emails"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Контакты"
          label="personEmailSelectField"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.person_name }} {{option.email_name}}
            <button
                class="delete-button"
                @click.stop.prevent="removePersonEmail(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="personEmailModal" class="btn btn-primary">Добавить</button>
      <add-person-emails-modal ref="addPersonEmailsModal" v-show="isPersonEmailsModalVisible" @close="closePersonEmailModal"/>
    </div>
    <div class="form-group card-shadow">
      <h3>Кто может подать заявку</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorAppliesReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Кто организует конкурс и осуществляет финансирование</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorJuryReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Организатор (не отображается в карточке)</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorOrganizationReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Срок реализации</h3>
    <multiselect
        v-model="hse_grand.deadline_id"
        :options="deadlines"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Срок реализации"
        label="name"
        track-by="id"
        :searchable="true"
    >
      <template v-slot:option="{option}">
        <div class="custom-option">
          {{ option.name }}
          <button
              class="delete-button"
              @click.stop.prevent="removeDeadline(option.id)"
              aria-label="Delete"
          >
            &times;
          </button>
        </div>
      </template>
    </multiselect>
      <button type="button" @click="deadlineModal" class="btn btn-primary">Добавить</button>
      <add-deadline-modal v-show="isDeadlineModalVisible" @close="closeDeadlineModal"/>
    </div>

    <div class="form-group card-shadow">
      <h3>Срок реализации проекта</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorDeadlineCommentReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Размер финансирования</h3>
      <multiselect
          v-model="selectedFinancingValues"
          :options="financing_values"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Размер финансирования"
          label="name"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.name }}
            <button
                class="delete-button"
                @click.stop.prevent="removeFinancingValue(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="financingValuesModal" class="btn btn-primary">Добавить</button>
      <add-financing-value-modal v-show="isFinancingValuesModalVisible" @close="closeFinancingValuesModal"/>
    </div>
    <div class="form-group card-shadow">
      <h3>Условия и размер финансирования</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorFinancingCommentReady"
      />
    </div>
    <div class="form-group card-shadow">
      <h3>Требования к участникам</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorParticipantRequirementsReady"
      />
    </div>

    <div class="form-group card-shadow">
      <h3>Прекращение или сокращения финансирование</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorFundingReductionReady"
      />
    </div>

    <div class="form-group card-shadow">
      <h3>Права и требования к указанию аффилиации</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorAffilationReady"
      />
    </div>

    <div class="form-group card-shadow">
      <h3>Конкурсные треки</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorAreaReady"
      />
    </div>

    <div class="form-group card-shadow">
      <h3>Критерии оценки заявок</h3>
      <div v-for="(criteria, index) in criterias" :key="index">
        <quill-editor
            class="form-control form-control-dark"
            :options="editorOptions"
            @ready="onEditorCriteriaReady(index, $event)"
        />
      </div>
      <div class="btn-group">
      <button v-if="this.criterias.length>1" type="button" @click="deleteCriteriaFromList" class="btn btn-danger">Удалить</button>
      <button type="button" class="btn btn-primary" @click="addCriteriaToList">Добавить ещё</button>
      </div>
    </div>
    <div class="form-group card-shadow">
      <h3>Отрасли науки</h3>
    <multiselect
        v-model="selectedScienceBranches"
        :options="science_branches"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Отрасли науки"
        label="name"
        track-by="id"
        :searchable="true"
    >
      <template v-slot:option="{option}">
        <div class="custom-option">
          {{ option.name }}
          <button
              class="delete-button"
              @click.stop.prevent="removeScienceBranch(option.id)"
              aria-label="Delete"
          >
            &times;
          </button>
        </div>
      </template>
    </multiselect>
    <button type="button" @click="scienceBranchModal" class="btn btn-primary">Добавить</button>
    <add-science-branch-modal v-show="isScienceBranchModalVisible" @close="closeScienceBranchModal"/>
    </div>
    <button type="submit" class="btn btn-success">Добавить грант</button>
  </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import Multiselect from "vue-multiselect";
import {quillEditor}  from "vue3-quill";
import addTypeOfResearchModal from "../modals/addTypeOfResearchModal.vue";
import addResultRequirementsModal from "../modals/addResultRequirementsModal.vue";
import addRegularitiesModal from "@/components/modals/addRegularitiesModal.vue";
import addPeriodsModal from "@/components/modals/addPeriodsModal.vue";
import addCompetitionTypeModal from "@/components/modals/addCompetitionTypeModal.vue";
import addDeadlineModal from "@/components/modals/addDeadlineModal.vue";
import addScienceBranchModal from "@/components/modals/addScienceBranchModal.vue";
import addPersonEmailsModal from "@/components/modals/addPersonEmailsModal.vue";
import AdminNavBar from "@/components/AdminNavBar.vue";
import {isAuth} from "@/mixins/authCheck";
import AddFinancingValueModal from "@/components/modals/addFinancingValueModal.vue";

export default {
  name: "addTask",
  components: {
    AddFinancingValueModal,
    AdminNavBar,
    Multiselect,
    quillEditor,
    addResultRequirementsModal,
    addTypeOfResearchModal,
    addRegularitiesModal,
    addPeriodsModal,
    addCompetitionTypeModal,
    addDeadlineModal,
    addScienceBranchModal,
    addPersonEmailsModal
  },
  mixins: [isAuth],
  data() {
    return {
      hse_grand: {
        relevance: "",
        regularity_id: null,
        period_id: null,
        competition_type_id: null,
        format_name: "",
        format_description: "",
        format_website: "",
        regulations: "",
        apply_needs: "",
        jury: "",
        organization: "",
        deadline_id: null,
        deadline_comment: "",
        financing_comment: "",
        participant_requirements: "",
        funding_reduction: "",
        affilation: "",
        area: ""
      },
      type_of_researches: [],
      selectedResearchTypes: [],
      isTypeOfResearchModalVisible: false,
      financing_values: [],
      selectedFinancingValues: [],
      isFinancingValuesModalVisible: false,
      result_requirements: [],
      selectedResult_requirements: [],
      isResultRequirementsModalVisible: false,
      quillInstanceRelevance: null,
      regularities:[],
      isAllRegularitiesShowed: false,
      isRegularitiesModalVisible: false,
      periods: [],
      isAllPeriodsShowed: false,
      isPeriodsModalVisible: false,
      competition_types: [],
      isCompetitionTypesModalVisible: false,
      quillInstanceFormatName: null,
      quillInstanceFormatDescription: null,
      quillInstanceFormatWebsite: null,
      qullInstanceRegulations: null,
      person_emails: [],
      selectedPersonEmails: [],
      isPersonEmailsModalVisible: false,
      criterias: [
        {
          direction_criteria: "",
          quillInstanceCriteria: null
        }
      ],
      quillInstanceApplies: null,
      quillInstanceJury: null,
      quillInstanceFinancingComment: null,
      quillInstanceOrganization: null,
      deadlines: [],
      isDeadlineModalVisible: false,
      quillInstanceDeadlineComment: null,
      quillInstanceParticipantRequirements: null,
      quillInstanceFundingReduction: null,
      quillInstanceAffilation: null,
      quillInstanceArea: null,
      science_branches: [],
      selectedScienceBranches: [],
      isScienceBranchModalVisible: false,
      editorOptions: {
        theme: 'snow',
        placeholder: 'Введите текст',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ]
        }
      }
    };
  },
  methods: {
    async addHseGrand(e) {
      try {
        e.preventDefault();
        const htmlRelevance = this.quillInstanceRelevance.root.innerHTML;
        const htmlFormatName = this.quillInstanceFormatName.root.innerHTML;
        const htmlFormatDescription = this.quillInstanceFormatDescription.root.innerHTML;
        const htmlFormatWebsite = this.quillInstanceFormatWebsite.root.innerHTML;
        const htmlRegulations = this.qullInstanceRegulations.root.innerHTML;
        const htmlApplies = this.quillInstanceApplies.root.innerHTML;
        const htmlJury = this.quillInstanceJury.root.innerHTML;
        const htmlOrganization = this.quillInstanceOrganization.root.innerHTML;
        const htmlDeadlineComment = this.quillInstanceDeadlineComment.root.innerHTML;
        const htmlParticipantRequirements = this.quillInstanceParticipantRequirements.root.innerHTML;
        const htmlFundintReduction = this.quillInstanceFundingReduction.root.innerHTML;
        const htmlAffilation = this.quillInstanceAffilation.root.innerHTML;
        const htmlArea = this.quillInstanceArea.root.innerHTML;
        const htmlFinancingComment = this.quillInstanceFinancingComment.root.innerHTML;
        var data = {
          relevance: htmlRelevance,
          regularity_id: this.hse_grand.regularity_id.id,
          period_id: this.hse_grand.period_id.id,
          competition_type_id: this.hse_grand.competition_type_id.id,
          format_name: htmlFormatName,
          format_description: htmlFormatDescription,
          format_website: htmlFormatWebsite,
          regulations: htmlRegulations,
          apply_needs: htmlApplies,
          jury: htmlJury,
          organization: htmlOrganization,
          deadline_id: this.hse_grand.deadline_id.id,
          deadline_comment: htmlDeadlineComment,
          financing_comment: htmlFinancingComment,
          participant_requirements: htmlParticipantRequirements,
          funding_reduction: htmlFundintReduction,
          affilation: htmlAffilation,
          area: htmlArea,
        };
        const response = await http.post("/addHse_grand", data);
        for(var researchType in this.selectedResearchTypes){
          var dataTR = {
            hse_grandees_id: response.data.id,
            external_grandees_id: null,
            type_of_research_id: this.selectedResearchTypes[researchType].id
          }
          await http.post('/addGrandees_type_of_research',dataTR);
        }
        for(var resultreq in this.selectedResult_requirements){
          var dataRR = {
            hse_grandees_id: response.data.id,
            external_grandees_id: null,
            result_requirements_id: this.selectedResult_requirements[resultreq].id
          }
          await http.post('/addGrandees_result_requirement',dataRR);
        }
        for(var personemail in this.selectedPersonEmails){
          var dataPE = {
            hse_grandees_id: response.data.id,
            hse_grandees_detailed_id: null,
            person_email_id: this.selectedPersonEmails[personemail].id
          }
          await http.post('/addGrandees_person_email',dataPE);
        }
        for(var sciencebranch in this.selectedScienceBranches){
          var dataSB = {
            hse_grandees_id: response.data.id,
            external_grandees_id: null,
            science_branch_id: this.selectedScienceBranches[sciencebranch].id
          }
          await http.post('/addGrandees_science_branch',dataSB);
        }
        for(var financing in this.selectedFinancingValues){
          var dataFV = {
            hse_grandees_id: response.data.id,
            external_grandees_id: null,
            financing_values_id: this.selectedFinancingValues[financing].id
          }
          await http.post('/addFinancing',dataFV);
        }
        for (let criteria of this.criterias) {
          if (criteria.quillInstanceCriteria) {
            criteria.direction_criteria = criteria.quillInstanceCriteria.root.innerHTML;
          }
        }
        for(var criteria in this.criterias){
          console.log(this.criterias[criteria]);
          var dataCriteria = {
            hse_grandees_id: response.data.id,
            direction_criteria: this.criterias[criteria].direction_criteria
          }
          await http.post('/addHse_grandees_criteria',dataCriteria);
        }
        console.log('added')
        window.location.href = '/admin/hseGrandees';
      } catch (e) {
        console.log(e);
      }
    },
    async getTypeOfResearches() {
      try {
        const response = await http.get("/type_of_researches");
        this.type_of_researches = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getResultRequirements(){
      try{
        const response = await http.get("/result_requirements");
        this.result_requirements = response.data;
      }catch(e){
        console.log(e);
      }
    },
    async getFinancingValues(){
      try{
        const response = await http.get('/financing_values');
        this.financing_values = await response.data;
      }catch (e){
        console.log(e)
      }
    },
    async removeFinancingValue(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteFinancing_value/${id}`);
        const response = await http.get('/financing_values');
        this.financing_values = await response.data;
      }catch (e){
        console.log(e)
      }
      }
    },
    financingValuesModal(){
      this.isFinancingValuesModalVisible = true;
    },
    async closeFinancingValuesModal(){
      this.isFinancingValuesModalVisible = false;
      await this.getFinancingValues();
    },
    async getRegularities(){
      try{
        var response;
        if(this.isAllRegularitiesShowed){
          response = await http.get("/regularities");
          this.isAllRegularitiesShowed = false;
        }else {
          response = await http.get('/regularities/defaultValues');
          this.isAllRegularitiesShowed = true;
        }
        this.regularities = response.data;
      }catch(e){
        console.log(e);
      }
    },

    async removeRegularity(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteRegularity/${id}`);
        const response = await http.get('/regularities');
        this.result_requirements = response.data;
      }catch(e){
        console.log(e)
      }
      }
    },
    async getPeriods(){
      try{
        var response;
        if(this.isAllPeriodsShowed){
          response = await http.get("/periods");
          this.isAllPeriodsShowed = false;
        }else {
          response = await http.get('/periods/defaultValues');
          this.isAllPeriodsShowed = true;
        }
        this.periods = response.data;
      }catch(e){
        console.log(e);
      }
    },
    async getCompetitionTypes(){
      try{
        const response = await http.get('/competition_types');
        this.competition_types = response.data;
      }catch (e){
        console.log(e);
      }
    },
    async getDeadlines(){
      try{
        const response = await http.get('/deadlines');
        this.deadlines = response.data;
      }catch (e){
        console.log(e);
      }
    },
    async getScienceBranches(){
      try{
        const response = await http.get('/science_branches');
        this.science_branches = response.data;
      }catch (e){
        console.log(e);
      }
    },
    typeOfResearchModal(){
      this.isTypeOfResearchModalVisible = true;
    },
    async closeTypeOfResearchModal(){
      this.isTypeOfResearchModalVisible = false;
      await this.getTypeOfResearches();
    },
    async removeResearchType(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteType_of_research/${id}`)
        await this.getTypeOfResearches();
      }catch(e){
        console.log(e)
      }
      }
    },
    async removeResultRequirements(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteResult_requirement/${id}`);
        await this.getResultRequirements();
      }catch(e){
        console.log(e)
      }
      }
    },
    resultRequirementsModal(){
     this.isResultRequirementsModalVisible = true;
    },
    async closeResultRequirementModal(){
      this.isResultRequirementsModalVisible = false;
      await this.getResultRequirements();
    },
    onEditorRelevanceReady(quill){
      this.quillInstanceRelevance = quill;
    },
    onEditorFinancingCommentReady(quill){
      this.quillInstanceFinancingComment = quill;
    },
    regularitiesModal(){
      this.isRegularitiesModalVisible = true;
    },
    async closeRegularitiesModal(){
      this.isRegularitiesModalVisible = false;
      await this.getRegularities();
    },
    async removePeriod(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deletePeriod/${id}`);
        const response = await http.get('/periods');
        this.periods = response.data;
      }catch(e){
        console.log(e)
      }
      }
    },
    periodsModal(){
      this.isPeriodsModalVisible = true;
    },
    async closePeriodsModal(){
      this.isPeriodsModalVisible = false;
      await this.getPeriods();
    },
    async removeCompetitionType(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteCompetition_type/${id}`);
        await this.getCompetitionTypes();
      }catch (e){
        console.log(e)
      }
      }
    },
    competitionTypeModal(){
      this.isCompetitionTypesModalVisible = true;
    },
    async closeCompetitionTypeModal(){
      this.isCompetitionTypesModalVisible = false;
      await this.getCompetitionTypes();
    },
    onEditorFormatNameReady(quill){
      this.quillInstanceFormatName = quill;
    },
    onEditorFormatDescriptionReady(quill){
      this.quillInstanceFormatDescription = quill;
    },
    onEditorFormatWebsiteReady(quill){
      this.quillInstanceFormatWebsite = quill;
    },
    onEditorRegulationsReady(quill){
      this.qullInstanceRegulations = quill;
    },
    onEditorAppliesReady(quill){
      this.quillInstanceApplies = quill;
    },
    onEditorJuryReady(quill){
      this.quillInstanceJury = quill;
    },
    onEditorOrganizationReady(quill){
      this.quillInstanceOrganization = quill;
    },
    deadlineModal(){
      this.isDeadlineModalVisible = true;
    },
    async closeDeadlineModal(){
      this.isDeadlineModalVisible = false;
      await this.getDeadlines();
    },
    async removeDeadline(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      await http.post(`/deleteDeadline/${id}`)
      await this.getDeadlines();
      }
    },
    onEditorDeadlineCommentReady(quill){
      this.quillInstanceDeadlineComment = quill;
    },
    onEditorParticipantRequirementsReady(quill){
      this.quillInstanceParticipantRequirements = quill;
    },
    onEditorFundingReductionReady(quill){
      this.quillInstanceFundingReduction = quill;
    },
    onEditorAffilationReady(quill){
      this.quillInstanceAffilation = quill;
    },
    onEditorCriteriaReady(index, quill) {
      this.$nextTick(() => {
        console.log(index)
        this.criterias[index].quillInstanceCriteria = quill;
        quill.root.innerHTML = this.criterias[index].direction_criteria;
      });
    },
    onEditorAreaReady(quill){
      this.quillInstanceArea = quill;
    },
    async removeScienceBranch(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deleteScience_branch/${id}`);
        await this.getScienceBranches();
      }catch(e){
        console.log(e);
      }
      }
    },
    scienceBranchModal(){
      this.isScienceBranchModalVisible = true;
    },
    async closeScienceBranchModal(){
      this.isScienceBranchModalVisible = false;
      await this.getScienceBranches();
    },
    async getPersonEmails(){
      try{
        var response = await http.get('/person_emails/names');
        response.data.forEach(a => {
          a.personEmailSelectField = `${a.person_name} - ${a.email_name}`
        })
        // console.log(response.data)
        this.person_emails = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async removePersonEmail(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deletePerson_email/${id}`);
        await this.getPersonEmails();
      }catch(e){
        console.log(e)
      }
      }
    },
    personEmailModal(){
      this.isPersonEmailsModalVisible = true;
      this.$refs.addPersonEmailsModal.getAvailableEmails();
    },
    async closePersonEmailModal(){
      this.isPersonEmailsModalVisible = false;
      await this.getPersonEmails();
    },
    addCriteriaToList(){
      this.criterias.push({direction_criteria: ''});
    },
    deleteCriteriaFromList(){
      this.criterias.pop();
    },
    async getTypeOfResearchNameById(name){
      try{
        const response = await http.get(`/type_of_researchByName/${name}`);
        return response.data;
      }catch (e){
        console.log(e)
      }
    }
  },
  mounted() {
    this.checkAuth();
    this.getTypeOfResearches();
    this.getResultRequirements();
    this.getRegularities();
    this.getPeriods();
    this.getCompetitionTypes();
    this.getDeadlines();
    this.getScienceBranches();
    this.getFinancingValues();
    this.getPersonEmails();
  }
};
</script>

<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "../../../node_modules/quill/dist/quill.snow.css";
.custom-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-right: 30px; /* Для отступа под крестик */
}

.delete-button {
  font-size: 24px; /* Сделаем крестик больше */
  color: rgba(231, 38, 38, 0.5); /* Полупрозрачный */
  position: absolute;
  right: 10px; /* Отступ от правого края */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: auto; /* Оставляем возможность клика на кнопку */
  background: transparent; /* Убираем фон кнопки */
  border: 10px; /* Убираем границу */
  z-index: 10; /* Поверх всего */
  cursor: pointer;
}

.delete-button:hover {
  color: rgba(0, 0, 0, 0.7); /* Добавим небольшой эффект при наведении */
}

#quill-editor{
  height: 1000px;
}
.form-control {
  word-wrap: break-word!important;
  word-break: break-all !important;
}
</style>
