<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список размеров финансирования</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_CLEARED}}
      </div>

      <ul class="list-group">
        <li v-for="(financingvalue, index) in financing_values" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="financingvalue.isEditing">
            <div class="input-group">
              <input v-model="financingvalue.name" class="form-control" placeholder="Размер финансирования"/>
            </div>
            <div class="btn-group">
              <button @click="updateFinancingValue(financingvalue)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(financingvalue)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="financingvalue-name">{{ financingvalue.name }}</span>
            </div>
            <div class="btn-group">
              <button @click="editCompetitionType(financingvalue)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(financingvalue.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addFinancingValue" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.financing_value.name" class="form-control" placeholder="Введите размер финансирования"/>
          <button type="submit" class="btn btn-primary ms-3">Добавить размер финансирования</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import { isAuth } from "@/mixins/authCheck";

export default {
  name: "ListFinancingValues",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      financing_values: [],
      financing_value: {
        name: ""
      }
    };
  },
  methods: {
    async getFinancingValues() {
      try {
        const response = await http.get(`/financing_values`);
        this.financing_values = response.data.map(fv => ({
          ...fv,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addFinancingValue(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.financing_value.name
        };
        await http.post("/addFinancing_value", data);
        this.financing_value = {
          name: ""
        };
        await this.getFinancingValues();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteFinancingValue(id);
      }
    },
    async deleteFinancingValue(id) {
      try {
        await http.post(`/deleteFinancing_value/${id}`);
        await this.getFinancingValues();
      } catch (e) {
        console.log(e);
      }
    },
    editCompetitionType(fv) {
      fv.isEditing = true;
    },
    async updateFinancingValue(fv) {
      try {
        const data = {
          name: fv.name
        };
        await http.post(`/updateFinancing_value/${fv.id}`, data);
        fv.isEditing = false;
        await this.getFinancingValues();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(fv) {
      fv.isEditing = false;
      this.getFinancingValues();
    }
  },
  mounted() {
    this.getFinancingValues();
    this.checkAuth();
  }
};
</script>
