<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список периодов</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_DELETED}}
      </div>

      <ul class="list-group">
        <li v-for="(period, index) in periods" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="period.isEditing">
            <div class="input-group">
              <input v-model="period.name" class="form-control" placeholder="Название периода"/>
              <div class="input-group-text">
                <label class="form-check-label">По умолчанию</label>
                <input type="checkbox" v-model="period.isDefaultValue" class="form-check-input ms-2"/>
              </div>
            </div>
            <div class="btn-group">
              <button @click="updatePeriod(period)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(period)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="period-name">{{ period.name }}</span>
              <span v-if="period.isDefaultValue" class="badge bg-info text-dark ms-2">Значение по умолчанию</span>
            </div>
            <div class="btn-group">
              <button @click="editPeriod(period)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(period.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addPeriod" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.period.name" class="form-control" placeholder="Введите название периода"/>
          <div class="input-group-text">
            <label class="form-check-label">Стандартное значение</label>
            <input type="checkbox" v-model="this.period.isDefaultValue" class="form-check-input ms-2"/>
          </div>
          <button type="submit" class="btn btn-primary ms-3">Добавить период</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import { isAuth } from "@/mixins/authCheck";
import '@/styles/elementsWithDefaultValues.css'

export default {
  name: "ListPeriods",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      periods: [],
      periodToAdd: {
        id: null,
        name: "",
        isDefaultValue: false,
      },
      period: {
        name: "",
        isDefaultValue: false
      }
    };
  },
  methods: {
    async getPeriods() {
      try {
        const response = await http.get(`/periods`);
        this.periods = response.data.map(period => ({
          ...period,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addPeriod(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.period.name,
          isDefaultValue: this.period.isDefaultValue
        };
        await http.post("/addPeriod", data);
        this.period = {
          name: "",
          isDefaultValue: false,
        };
        await this.getPeriods();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deletePeriod(id);
      }
    },
    async deletePeriod(id){
      try{
        await http.post(`/deletePeriod/${id}`);
        await this.getPeriods();
      }catch (e){
        console.log(e)
      }
    },
    editPeriod(period) {
      period.isEditing = true;
    },
    async updatePeriod(period) {
      try {
        const data = {
          name: period.name,
          isDefaultValue: period.isDefaultValue
        };
        await http.post(`/updatePeriod/${period.id}`, data);
        period.isEditing = false;
        await this.getPeriods();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(period) {
      period.isEditing = false;
      this.getPeriods();
    }
  },
  mounted() {
    this.getPeriods();
    this.checkAuth();
  }
};
</script>
