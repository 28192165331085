<template>
  <transition name="modal-fade">
    <div class="modal-backdrop-custom" @click="close">
      <form @submit.prevent="addFederalDistrict" class="modal-custom card-shadow" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <header class="modal-header-custom" id="modalTitle">
          <slot name="header">
            <h2 class="modal-title">Добавить федеральный округ</h2>
            <button type="button" class="btn-close" @click="close" aria-label="Close modal">&times;</button>
          </slot>
        </header>
        <section class="modal-body-custom" id="modalDescription">
          <slot name="body">
            <div class="form-group">
              <h3>Название</h3>
              <input type="text" placeholder="Введите название" class="form-control form-control-dark" id="name" v-model="federal_district.name" required>
            </div>
          </slot>
        </section>
        <footer class="modal-footer-custom">
          <slot name="footer">
            <button type="submit" class="btn btn-primary btn-custom">Добавить</button>
          </slot>
        </footer>
      </form>
    </div>
  </transition>
</template>

<script>
import http from "../../http-common";
import '@/styles/modalWindow.css'

export default {
  name: 'AddFederalDistrictModal',
  data() {
    return {
      federal_district: {
        id: null,
        name: ""
      }
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async addFederalDistrict() {
      try {
        var data = {
          name: this.federal_district.name
        };
        await http.post("/addFederal_district", data);
        this.federal_district.name = "";
        this.close();
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>