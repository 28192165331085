<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список городов</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_DELETED}}
      </div>

      <ul class="list-group">
        <li v-for="(city, index) in cities" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="city.isEditing">
            <div class="input-group">
              <input v-model="city.name" class="form-control" placeholder="Название города"/>
            </div>
            <div class="btn-group">
              <button @click="updateCity(city)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(city)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="city-name">{{ city.name }}</span>
            </div>
            <div class="btn-group">
              <button @click="editCity(city)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(city.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addCity" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.city.name" class="form-control" placeholder="Введите название города"/>
          <button type="submit" class="btn btn-primary ms-3">Добавить город</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import { isAuth } from "@/mixins/authCheck";

export default {
  name: "ListCity",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      cities: [],
      city: {
        name: ""
      }
    };
  },
  methods: {
    async getCities() {
      try {
        const response = await http.get(`/cities`);
        this.cities = response.data.map(city => ({
          ...city,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addCity(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.city.name
        };
        await http.post("/addCity", data);
        this.city = {
          name: ""
        };
        await this.getCities();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteCity(id);
      }
    },
    async deleteCity(id) {
      try {
        await http.post(`/deleteCity/${id}`);
        await this.getCities();
      } catch (e) {
        console.log(e);
      }
    },
    editCity(city) {
      city.isEditing = true;
    },
    async updateCity(city) {
      try {
        const data = {
          name: city.name
        };
        await http.post(`/updateCity/${city.id}`, data);
        city.isEditing = false;
        await this.getCities();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(city) {
      city.isEditing = false;
      this.getCities();
    }
  },
  mounted() {
    this.getCities();
    this.checkAuth();
  }
};
</script>
