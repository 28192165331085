import { createApp } from 'vue' // Импорт метода для создания приложения
import App from './App.vue' // Импорт главного компонента
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap';
import './assets/fonts/fonts.css'
import '@/styles/mainstyle.css'
import router from './router' // Маршрутизация
import store from './store';


const app = createApp(App); // Создание экземпляра приложения
app.config.globalProperties.$ALERT_VALUES_WILL_BE_DELETED = "При удалении элемента, все связанные с ним данные будут также удалены! Рекомендуется избегать удаления и по возможности редактировать существующие значения."
app.config.globalProperties.$ALERT_VALUES_WILL_BE_CLEARED = "При удалении элемента, все связанные с ним данные будут содержать пустые значения! Рекомендуется избегать удаления и по возможности редактировать существующие значения."
app.use(router); // Подключение маршрутизации
app.use(store);
app.config.errorHandler = () => null;
app.config.warnHandler = () => null;
app.mount('#app'); // Привязка экземпляра приложения к странице HTML (находится в public)