<template>
  <div class="login-container d-flex justify-content-center align-items-center">
    <div class="login-box">
      <div class="text-center mb-4">
        <img src="@/icons/01_Logo_HSE_full_rus_PANTONE_for_dark_1.png" alt="HSE Logo" class="hse-logo" />
      </div>
      <h4 class="text-center mb-4">Вход в систему</h4>
      <form name="form" @submit="handleLogin">
        <div class="form-group">
          <input
              type="text"
              class="form-control"
              name="login"
              placeholder="Логин"
              v-model="user.login"
              required
          />
        </div>
        <div class="form-group">
          <input
              type="password"
              class="form-control"
              name="password"
              placeholder="Пароль"
              v-model="user.password"
              required
          />
        </div>
        <div class="form-group text-center">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span v-if="!loading">Войти</span>
          </button>
        </div>
<!--        <div class="form-group text-center">-->
<!--          <router-link to="/register" class="register-link">Зарегистрироваться</router-link>-->
<!--        </div>-->
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoginUser',
  data() {
    return {
      user: {
        login: "",
        password: ""
      },
      loading: false,
      message: ''
    };
  },
  computed: { // вычисляемые свойства
    loggedIn() {
      return this.$store.state.auth.status.loggedIn; // $store - локальное хранилище
    }
  },
  created() {
    if (this.loggedIn) {
      // Авторизация прошла успешно, переходим к главной странице.
      // Используем такую конструкцию, а не this.$router.push, так как требуется перезагрузить страницу для обновления локального хранилища
      window.location.href = '/admin';
    }
  },
  methods: {
    handleLogin(e) {
      e.preventDefault();
      this.loading = true;
      this.$store.dispatch("auth/login", this.user) // обращаемся к методу login, который определён в auth.service.js
          .then(() => {
            window.location.href = '/admin'; // авторизация прошла успешно, переходим к главной странице. Используем такую конструкцию, а не this.$router.push, так как требуется перезагрузить страницу для обновления локального хранилища
          })
          .catch(e => {
                this.loading = false;
                this.message = e.response.data.message;
              }
          );
    }
  }
};
</script>
<style scoped>
.login-container {
  height: 100vh;
  background-color: #f8f9fa;
}

.login-box {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.hse-logo {
  width: 100px;
}

.form-control {
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 10px;
}

.btn-block {
  width: 100%;
}

.register-link {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.register-link:hover {
  text-decoration: underline;
}

.alert {
  margin-top: 20px;
}
</style>