<template>
  <transition name="modal-fade">
    <div class="modal-backdrop-custom" @click="close">
      <form @submit.prevent="addPersonEmail" class="modal-custom card-shadow" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <header class="modal-header-custom" id="modalTitle">
          <slot name="header">
            <h2 class="modal-title">Добавить руководителей</h2>
            <button type="button" class="btn-close" @click="close" aria-label="Close modal">&times;</button>
          </slot>
        </header>
        <section class="modal-body-custom" id="modalDescription">
          <slot name="body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <multiselect
                      v-model="person_email.person_id"
                      :options="persons"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Выберите руководителя"
                      label="name"
                      track-by="id"
                      :searchable="true"
                  >
                    <template v-slot:option="{ option }">
                      <div class="custom-option">
                        {{ option.name }}
                        <button
                            class="delete-button"
                            @click.stop.prevent="removePerson(option.id)"
                            aria-label="Delete"
                        >
                          &times;
                        </button>
                      </div>
                    </template>
                  </multiselect>
                </div>
                <div class="form-group">
                  <input
                      type="text"
                      class="form-control form-control-dark"
                      v-model="person.name"
                      placeholder="Добавить нового руководителя"
                  />
                  <button type="button" class="btn btn-primary btn-custom mt-2" @click="addPerson">Добавить руководителя</button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <multiselect
                      v-model="person_email.email_id"
                      :options="emails"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Выберите почту"
                      label="name"
                      track-by="id"
                      :searchable="true"
                  >
                    <template v-slot:option="{ option }">
                      <div class="custom-option">
                        {{ option.name }}
                        <button
                            class="delete-button"
                            @click.stop.prevent="removeEmail(option.id)"
                            aria-label="Delete"
                        >
                          &times;
                        </button>
                      </div>
                    </template>
                  </multiselect>
                </div>
                <div class="form-group">
                  <input
                      type="text"
                      class="form-control form-control-dark"
                      v-model="email.name"
                      placeholder="Добавить новую почту"
                  />
                  <button type="button" class="btn btn-primary btn-custom mt-2" @click="addEmail">Добавить почту</button>
                </div>
              </div>
            </div>
          </slot>
        </section>
        <footer class="modal-footer-custom">
          <slot name="footer">
            <button type="submit" class="btn btn-primary btn-custom">Добавить</button>
          </slot>
        </footer>
      </form>
    </div>
  </transition>
</template>

<script>
import http from "../../http-common";
import Multiselect from "vue-multiselect";
import '@/styles/modalWindow.css'

export default {
  name: 'AddPersonEmailModal',
  components: {
    Multiselect
  },
  data() {
    return {
      person_email: {
        id: null,
        person_id: null,
        email_id: null,
      },
      person_emails: [],
      persons: [],
      emails: [],
      person: {
        id: null,
        name: ""
      },
      email:{
        id: null,
        name: "",
      }
    };
  },
  methods: {
    close() {
      this.showModal = false;
      this.$emit('close');
    },
    async addPersonEmail() {
      try {
        var data = {
          person_id: this.person_email.person_id.id,
          email_id: this.person_email.email_id.id,
        };
        console.log(data)
        await http.post("/addPerson_email", data);
        this.person_email.person_id = null;
        this.person_email.email_id = null;
        await this.getAvailableEmails();
        // this.close();
      } catch (e) {
        console.log(e);
      }
    },
    async getPersons(){
      try{
        const response = await http.get('/persons');
        this.persons = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getAvailableEmails(){
      try{
        const response = await http.get(`/person_emails/availableEmails`);
        this.emails = response.data;
      }catch(e){
        console.log(e)
      }
    },
    async removePerson(id){
      try{
        await http.post(`/deletePerson/${id}`);
        await this.getPersons();
      }catch(e){
        console.log(e)
      }
    },
    async removeEmail(id){
      try{
        await http.post(`/deleteEmail/${id}`);
        await this.getAvailableEmails();
      }catch(e){
        console.log(e)
      }
    },
    async addPerson(e){
      e.preventDefault();
      try{
        const data = {
          name: this.person.name
        };
        await http.post('/addPerson',data);
        await this.getPersons();
        this.person.name = "";
      }
      catch (e){
        console.log(e)
      }
    },
    async addEmail(e){
      e.preventDefault();
      try{
        const data = {
          name: this.email.name
        };
        await http.post('/addEmail',data)
        await this.getAvailableEmails();
        this.email.name = "";
      }catch (e){
        console.log(e)
      }
    }
  },
  mounted() {
    this.getPersons();
    this.getAvailableEmails();
  }
};
</script>

<style scoped>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
</style>