<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список отраслей науки</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_CLEARED}}}
      </div>

      <ul class="list-group">
        <li v-for="(sciencebranch, index) in sciencebranches" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="sciencebranch.isEditing">
            <div class="input-group">
              <input v-model="sciencebranch.name" class="form-control" placeholder="Название отрасли науки"/>
            </div>
            <div class="btn-group">
              <button @click="updateScienceBranch(sciencebranch)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(sciencebranch)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="sciencebranch-name">{{ sciencebranch.name }}</span>
            </div>
            <div class="btn-group">
              <button @click="editScienceBranch(sciencebranch)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(sciencebranch.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addScienceBranch" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.sciencebranch.name" class="form-control" placeholder="Введите название отрасли науки"/>
          <button type="submit" class="btn btn-primary ms-3">Добавить отрасль науки</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import { isAuth } from "@/mixins/authCheck";

export default {
  name: "ListScienceBranch",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      sciencebranches: [],
      sciencebranch: {
        name: ""
      }
    };
  },
  methods: {
    async getScienceBranches() {
      try {
        const response = await http.get(`/science_branches`);
        this.sciencebranches = response.data.map(sciencebranch => ({
          ...sciencebranch,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addScienceBranch(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.sciencebranch.name
        };
        await http.post("/addScience_branch", data);
        this.sciencebranch = {
          name: ""
        };
        await this.getScienceBranches();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteScienceBranch(id);
      }
    },
    async deleteScienceBranch(id) {
      try {
        await http.post(`/deleteScience_branch/${id}`);
        await this.getScienceBranches();
      } catch (e) {
        console.log(e);
      }
    },
    editScienceBranch(sciencebranch) {
      sciencebranch.isEditing = true;
    },
    async updateScienceBranch(sciencebranch) {
      try {
        const data = {
          name: sciencebranch.name
        };
        await http.post(`/updateScience_branch/${sciencebranch.id}`, data);
        sciencebranch.isEditing = false;
        await this.getScienceBranches();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(sciencebranch) {
      sciencebranch.isEditing = false;
      this.getScienceBranches();
    }
  },
  mounted() {
    this.getScienceBranches();
    this.checkAuth();
  }
};
</script>
