<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Список факультетов</h1>
      <div class="alert alert-warning" role="alert">
        {{$ALERT_VALUES_WILL_BE_DELETED}}
      </div>

      <ul class="list-group">
        <li v-for="(faculty, index) in faculties" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          <template v-if="faculty.isEditing">
            <div class="input-group">
              <input v-model="faculty.name" class="form-control" placeholder="Название факультета"/>
            </div>
            <div class="btn-group">
              <button @click="updateFaculty(faculty)" class="btn btn-success">Сохранить</button>
              <button @click="cancelEdit(faculty)" class="btn btn-danger">Отмена</button>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <span class="faculty-name">{{ faculty.name }}</span>
            </div>
            <div class="btn-group">
              <button @click="editFaculty(faculty)" class="btn btn-primary">Изменить</button>
              <button class="btn btn-danger" type="button" @click="confirmDelete(faculty.id)">Удалить</button>
            </div>
          </template>
        </li>
      </ul>

      <form @submit="addFaculty" class="mt-4">
        <div class="input-group mb-3">
          <input v-model="this.faculty.name" class="form-control" placeholder="Введите название факультета"/>
          <button type="submit" class="btn btn-primary ms-3">Добавить факультет</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import AdminNavBar from "@/components/AdminNavBar.vue";
import {isAuth} from "@/mixins/authCheck";

export default {
  name: "ListFaculty",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      faculties: [],
      faculty: {
        name: ""
      }
    };
  },
  methods: {
    async getFaculties() {
      try {
        const response = await http.get(`/faculties`);
        this.faculties = response.data.map(faculty => ({
          ...faculty,
          isEditing: false
        }));
      } catch (e) {
        console.log(e);
      }
    },
    async addFaculty(e) {
      e.preventDefault();
      try {
        const data = {
          name: this.faculty.name
        };
        await http.post("/addFaculty", data);
        this.faculty = {
          name: ""
        };
        await this.getFaculties();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteFaculty(id);
      }
    },
    async deleteFaculty(id) {
      try {
        await http.post(`/deleteFaculty/${id}`);
        await this.getFaculties();
      } catch (e) {
        console.log(e);
      }
    },
    editFaculty(faculty) {
      faculty.isEditing = true;
    },
    async updateFaculty(faculty) {
      try {
        const data = {
          name: faculty.name
        };
        await http.post(`/updateFaculty/${faculty.id}`, data);
        faculty.isEditing = false;
        await this.getFaculties();
      } catch (e) {
        console.log(e);
      }
    },
    cancelEdit(faculty) {
      faculty.isEditing = false;
      this.getFaculties();
    }
  },
  mounted() {
    this.getFaculties();
    this.checkAuth();
  }
};
</script>
