<template>
  <div class="admin-page">
  <admin-nav-bar/>
    <div class="admin-content">
      <h1>Добавить вложенный грант ВШЭ</h1>
  <form @submit="addHseDetailed">
    <div class="form-group card-shadow">
      <h3>Привязка к гранту ВШЭ</h3>
      <multiselect
          v-model="hse_detailed.hse_grandees_id"
          :options="hse_grandees"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Грант ВШЭ"
          label="hseGrandeesName"
          track-by="id"
          :searchable="true"
      >
      </multiselect>
    </div>
    <div class="form-group card-shadow">
      <h3>Актуальность</h3>
      <input class="form-check-input ms-2" type="checkbox" v-model="hse_detailed.actuality">
    </div>
    <div class="form-group card-shadow">
      <h3>Сайт</h3>
      <quill-editor
        class="form-control form-control-dark"
        :options="editorOptions"
        @ready="onEditorWebsiteInfoReady"
    />
    </div>
    <div class="form-group card-shadow">
      <h3>Контакты</h3>
      <multiselect
          v-model="selectedPersonEmails"
          :options="person_emails"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Контакты"
          label="personEmailSelectField"
          track-by="id"
          :searchable="true"
      >
        <template v-slot:option="{ option}">
          <div class="custom-option">
            {{ option.person_name }} {{option.email_name}}
            <button
                class="delete-button"
                @click.stop.prevent="removePersonEmail(option.id)"
                aria-label="Delete"
            >
              &times;
            </button>
          </div>
        </template>
      </multiselect>
      <button type="button" @click="personEmailModal" class="btn btn-primary">Добавить</button>
      <add-person-emails-modal ref="addPersonEmailsModal" v-show="isPersonEmailsModalVisible" @close="closePersonEmailModal"/>
    </div>
    <div class="form-group card-shadow">
      <h3>Факультет</h3>
      <multiselect
          v-model="hse_detailed.faculty_id"
          :options="faculties"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Факультет"
          label="name"
          track-by="id"
          :searchable="true"
      >
      </multiselect>
    </div>
    <div class="form-group card-shadow">
      <h3>Сроки подачи заявок</h3>
      <input class="form-control" placeholder="Введите срок подачи заявок" type="text" v-model="hse_detailed.deadline_submission">
    </div>
    <div class="form-group card-shadow">
      <h3>Почта</h3>
      <input class="form-control" placeholder="Введите почту" type="text" v-model="hse_detailed.email">
    </div>
    <div class="form-group card-shadow">
      <h3>Объем финансирования</h3>
      <input class="form-control" placeholder="Введите объем финансирования" type="text" v-model="hse_detailed.financing">
    </div>
    <div class="form-group card-shadow">
      <h3>Комментарий</h3>
      <quill-editor
          class="form-control form-control-dark"
          :options="editorOptions"
          @ready="onEditorCommentReady"
      />
    </div>
    <button type="submit" class="btn btn-primary">Добавить грант</button>
  </form>
  </div>
  </div>
</template>

<script>
import http from "../../http-common";
import {isAuth} from "@/mixins/authCheck";
import AdminNavBar from "@/components/AdminNavBar.vue";
import addPersonEmailsModal from "@/components/modals/addPersonEmailsModal.vue";
import Multiselect from "vue-multiselect";
import {quillEditor} from "vue3-quill";
export default {
  name: "ListDetailedHseGrandAdmin",
  mixins: [isAuth],
  components: {
    AdminNavBar,
    Multiselect,
    quillEditor,
    addPersonEmailsModal
  },
  data() {
    return {
      hse_detailed: {
        hse_grandees_id : null,
        actuality: 0,
        website_id: "",
        faculty_id: null,
        deadline_submission: "",
        email: "",
        financing: "",
        comment: ""
      },
      person_emails: [],
      selectedPersonEmails: [],
      isPersonEmailsModalVisible: false,
      faculties: [],
      hse_grandees: [],
      quillInstanceWebsiteInfo: "",
      quillInstanceComment: "",
      editorOptions: {
        theme: 'snow',
        placeholder: 'Введите текст',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ]
        }
      }
    };
  },
  methods: {
    async addHseDetailed(e){
      try{
        e.preventDefault();
        const htmlWebsiteInfo = this.quillInstanceWebsiteInfo.root.innerHTML;
        const htmlComment = this.quillInstanceComment.root.innerHTML;
        var data = {
          hse_grandees_id: this.hse_detailed.hse_grandees_id.id,
          actuality: this.hse_detailed.actuality,
          website_info: htmlWebsiteInfo,
          faculty_id: this.hse_detailed.faculty_id.id,
          deadline_submission: this.hse_detailed.deadline_submission,
          email: this.hse_detailed.email,
          financing: this.hse_detailed.financing,
          comment: htmlComment
        }
        const response = await http.post('/addHse_grand_detailed',data);
        for(var personemail in this.selectedPersonEmails){
          var dataPE = {
            hse_grandees_id: null,
            hse_grandees_detailed_id: response.data.id,
            person_email_id: this.selectedPersonEmails[personemail].id
          }
          await http.post('/addGrandees_person_email',dataPE);
        }
        console.log('added')
        window.location.href = '/admin/hseGrandeesDetailed'
      }catch(error){
        console.log(error)
      }
    },
    onEditorWebsiteInfoReady(quill){
      this.quillInstanceWebsiteInfo = quill;
    },
    onEditorCommentReady(quill){
      this.quillInstanceComment = quill;
    },
    async getFaculties(){
      try{
        const response = await http.get("/faculties");
        this.faculties = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getHseGrandees(){
      try{
        const response = await http.get("/hse_grandees");
        response.data.forEach(a => {
          const firstPart = a.format_name.replace(/<[^>]*>/g, '').substring(0,30);
          const secondPart = a.format_description.replace(/<[^>]*>/g, '').substring(0,30);
          a.hseGrandeesName = `${firstPart} - ${secondPart}`;
        })
        this.hse_grandees = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async getPersonEmails(){
      try{
        var response = await http.get('/person_emails/names');
        response.data.forEach(a => {
          a.personEmailSelectField = `${a.person_name} - ${a.email_name}`
        })
        this.person_emails = response.data;
      }catch (e){
        console.log(e)
      }
    },
    async removePersonEmail(id){
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
      try{
        await http.post(`/deletePerson_email/${id}`);
        await this.getPersonEmails();
      }catch(e){
        console.log(e)
      }
      }
    },
    personEmailModal(){
      this.isPersonEmailsModalVisible = true;
      this.$refs.addPersonEmailsModal.getAvailableEmails();
    },
    async closePersonEmailModal(){
      this.isPersonEmailsModalVisible = false;
      await this.getPersonEmails();
    },
  },
  mounted() {
    this.checkAuth();
    this.getFaculties();
    this.getPersonEmails();
    this.getHseGrandees();
  }
}
</script>

<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "../../../node_modules/quill/dist/quill.snow.css";
.custom-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-right: 30px; /* Для отступа под крестик */
}

.delete-button {
  font-size: 24px; /* Сделаем крестик больше */
  color: rgba(231, 38, 38, 0.5); /* Полупрозрачный */
  position: absolute;
  right: 10px; /* Отступ от правого края */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: auto; /* Оставляем возможность клика на кнопку */
  background: transparent; /* Убираем фон кнопки */
  border: 10px; /* Убираем границу */
  z-index: 10; /* Поверх всего */
  cursor: pointer;
}

.delete-button:hover {
  color: rgba(0, 0, 0, 0.7); /* Добавим небольшой эффект при наведении */
}

.test{
  outline-style: dotted;
  margin: 5px;
}
#quill-editor{
  height: 1000px;
}
.form-control {
  word-wrap: break-word!important;
  word-break: break-all !important;
}
</style>