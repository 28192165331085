<template>
  <div class="admin-page">
    <admin-nav-bar />
    <div class="admin-content">
      <h1>Добро пожаловать в панель администрирования</h1>
    </div>
  </div>
</template>

<script>
import AdminNavBar from '@/components/AdminNavBar.vue';

export default {
  name: 'AdminPage',
  components: {
    AdminNavBar
  },
  methods: {
    async checkRoot() {
      if (this.$store.state.auth.user === null) {
        window.location.href = '/admin/login';
      }
    }
  },
  mounted() {
    this.checkRoot();
  }
};
</script>

<style scoped>
.admin-container {
  display: flex;
}

.admin-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px;
  box-sizing: border-box;
}

h1 {
  font-size: 28px;
  color: #003366;
}
</style>
