import { createWebHistory, createRouter } from "vue-router";
import store from "./store/index";
// импорт компонентов
import ListCities from "./components/values/ListCities.vue";
import Login from "./components/authorization/Login";
// import Register from "./components/authorization/Register";
import ListHseGrandees from "@/components/hse_grandees/ListHseGrandees.vue";
import AddHseGrand from "@/components/values/addHseGrand.vue";
import ListExternalGrandees from "@/components/external_grandees/ListExternalGrandees.vue";
import AddExternalGrand from "@/components/values/addExternalGrand.vue";
import ListPeriod from "@/components/values/ListPeriod.vue";
import AdminPage from "@/components/values/AdminPage.vue";
import ListRegularity from "@/components/values/ListRegularity.vue";
import ListCompetitionType from "@/components/values/ListCompetitionType.vue";
import ListScienceBranch from "@/components/values/ListScienceBranch.vue";
import ListTypeOfResearch from "@/components/values/ListTypeOfResearch.vue";
import ListResultRequirements from "@/components/values/ListResultRequirements.vue";
import ListFaculty from "@/components/values/ListFaculty.vue";
import ListRegion from "@/components/values/ListRegion.vue";
import ListFederalDistrict from "@/components/values/ListFederalDistrict.vue";
import ListDeadlines from "@/components/values/ListDeadlines.vue";
import ListPersonEmail from "@/components/values/ListPersonEmail.vue";
import ListHseGrandAdmin from "@/components/values/ListHseGrandAdmin.vue";
import HseGrand from "@/components/values/HseGrand.vue";
import ListExternalGrandAdmin from "@/components/values/ListExternalGrandAdmin.vue";
import ExternalGrand from "@/components/values/ExternalGrand.vue";
import ListDetailedHseGrandeesAdmin from "@/components/values/ListDetailedHseGrandeesAdmin.vue";
import addHseDetailedGrand from "@/components/values/addHseDetailedGrand.vue";
import HseDetailedGrand from "@/components/values/HseDetailedGrand.vue";
import ListFinancingValues from "@/components/values/ListFinancingValues.vue";
import HseGrandUser from '@/components/hse_grandees/HseGrand.vue'
import ExternalGrandUser from '@/components/external_grandees/ExternalGrand.vue'



// определяем маршруты
const routes = [
    {
      path: "/",
      redirect: "/hseGrandees",
        meta: {
          title: "Навигатор грантовой поддержки НИУ ВШЭ и институтов развития"
        }
    },
    {
      path:"/admin",
      name: "admin-page",
      component: AdminPage,
      meta:{
          title: "Панель администратора"
      }
    },
    {
        path: "/listCities", // указание маршрута, по которому будем переходить к списку учебных дисциплин
        name: "cities", // имя маршрута
        alias: "/admin/cities", // указание дополнительного маршрута
        component: ListCities, // компонент, на основании которого будет отрисовываться страница
        meta: {
            title: "Список городов"
        }
    },
    {
        path: "/admin/login",
        name: "login-user",
        component: Login,
        meta: {
            title: "Вход в систему"
        }
    },
    // {
    //     path: "/admin/register",
    //     name: "register-user",
    //     component: Register,
    //     meta: {
    //         title: "Регистрация"
    //     }
    // },
    {
        path: "/hseGrandees",
        name: "hse-grandees",
        component: ListHseGrandees,
        meta: {
            title: "Конкурсы и гранты НИУ ВШЭ"
        }
    },
    {
        path: "/admin/addHseGrand",
        name: "addHseGrand",
        component: AddHseGrand,
        meta:{
            title: "Добавления гранта ВШЭ"
        }
    },
    {
        path: "/externalGrandees",
        name: "external-grandees",
        component: ListExternalGrandees,
        meta:{
            title: "Внешние конкурсы и гранты"
        }
    },
    {
        path:"/admin/addExternalGrand",
        name: "addExternalGrand",
        component: AddExternalGrand,
        meta: {
            title: "Добавления гранта внешних организаций"
        }
    },
    {
        path: "/admin/periods",
        name: "listPeriods",
        component: ListPeriod,
        meta:{
            title: "Периоды"
        }
    },
    {
        path: "/admin/regularities",
        name: "regularities",
        component: ListRegularity,
        meta: {
            title: "Регулярности"
        }
    },
    {
        path: "/admin/competitionTypes",
        name: "competitionTypes",
        component: ListCompetitionType,
        meta: {
            title: "Типы конкурсов"
        }
    },
    {
        path: "/admin/scienceBranches",
        name: "scienceBranches",
        component: ListScienceBranch,
        meta: {
            title: "Отрасли науки"
        }
    },
    {
        path: "/admin/typeOfResearches",
        name: "typeOfResearches",
        component: ListTypeOfResearch,
        meta: {
            title: "Типы исследования"
        }
    },
    {
        path: "/admin/resultRequirements",
        name: "resultRequirements",
        component: ListResultRequirements,
        meta: {
            title: "Требования к результату"
        }
    },
    {
        path: "/admin/faculties",
        name: "faculties",
        component: ListFaculty,
        meta: {
            title: "Факультеты"
        }
    },
    {
        path: "/admin/regions",
        name: "regions",
        component: ListRegion,
        meta: {
            title: "Регионы"
        }
    },
    {
        path: "/admin/federalDistricts",
        name: "federalDistricts",
        component: ListFederalDistrict,
        meta: {
            title: "Федеральные округи"
        }
    },
    {
        path: "/admin/deadlines",
        name: "deadlines",
        component: ListDeadlines,
        meta: {
            title: "Сроки реализации"
        }
    },
    {
        path: "/admin/personEmails",
        name: "personemails",
        component: ListPersonEmail,
        meta:{
            title: "Контакты пользователей"
        }
    },
    {
        path: "/admin/hseGrandees",
        name: "hsegrandees-admin",
        component: ListHseGrandAdmin,
        meta: {
            title: "Конкурсы и гранты НИУ ВШЭ"
        }
    },
    {
        path: `/admin/hseGrand/:id`,
        name: "hse_grand_detailed",
        component: HseGrand,
        props: true,
        meta: {
            title: "Редактирование гранта ВШЭ"
        }
    },
    {
        path: "/admin/externalGrandees",
        name: "externalgrandees-admin",
        component: ListExternalGrandAdmin,
        meta: {
            title: "Внешние конкурсы и гранты"
        }
    },
    {
        path: "/admin/externalGrand/:id",
        name: "external-grand-detailed",
        component: ExternalGrand,
        props: true,
        meta: {
            title: "Редактирование гранта внешней организации"
        }
    },
    {
        path: "/admin/hseGrandeesDetailed",
        name: "hse-grandeesDetailed",
        component: ListDetailedHseGrandeesAdmin,
        meta: {
            title: "Вложенные конкурсы и гранты ВШЭ"
        }
    },
    {
        path: "/admin/addDetailedHseGrand",
        name: "addHseGrandeesDetailed",
        component: addHseDetailedGrand,
        meta: {
            title: "Добавление вложенного конкурса ВШЭ"
        }
    },
    {
        path: "/admin/hseGrandDetailed/:id",
        name: "hse_detailed_grand",
        component: HseDetailedGrand,
        props: true,
        meta: {
            title: "Редактирование вложенного конкурса ВШЭ"
        }
    },
    {
        path: "/admin/financingValues",
        name: "financingValues",
        component: ListFinancingValues,
        meta: {
            title: "Размеры финансирования"
        }
    },
    {
        path: "/hseGrand/:id",
        name: "HseGrandUser",
        component: HseGrandUser,
        props: true,
        meta: {
            title: "Конкурс или грант НИУ ВШЭ"
        }
    },
    {
        path: "/externalGrand/:id",
        name: "ExternalGrandUser",
        component: ExternalGrandUser,
        props: true,
        meta: {
            title: "Конкурс или грант внешней организации"
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title || 'Главная страница';
    const auth = await store.getters["auth/isTokenActive"];
    if (auth) {
        return next();
    }
    else if (!auth && to.meta.requiredAuth) {
        const user = JSON.parse(localStorage.getItem("user"));
        await store.dispatch("auth/refreshToken", user)
            .then(() => {
                return next();
            })
            .catch(() => {
                return next({path: "/admin/login"});
            });
        return next({ path: "/admin/login" });
    }
    return next();
});

export default router;