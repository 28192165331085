<template>
  <div class="navbar">
    <img @click="homePage" src="../icons/01_Logo_HSE_full_rus_PANTONE_for_dark_1.png" alt="HSE Logo">
    <ul>
      <li>
        <router-link class="item" to="/admin/hseGrandees">Гранты ВШЭ</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/externalGrandees">Гранты внешних организаций</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/hseGrandeesDetailed">Вложенные конкурсы и гранты ВШЭ</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/competitionTypes">Типы конкурсов</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/scienceBranches">Отрасли науки</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/typeOfResearches">Типы исследования</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/resultRequirements">Требования к результату</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/financingValues">Размеры финансирования</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/personEmails">Контакты пользователей</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/periods">Периоды</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/cities">Города</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/regularities">Регулярности</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/faculties">Факультеты</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/regions">Регионы</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/federalDistricts">Федеральные округи</router-link>
      </li>
      <li>
        <router-link class="item" to="/admin/deadlines">Сроки реализации</router-link>
      </li>
      <li class="logout-icon">
        <img src="@/icons/logout.svg" @click.prevent="logOut" alt="Logout Icon">
      </li>
    </ul>
  </div>
</template>

<script>
import {isAuth} from '@/mixins/authCheck'
// import '@/styles/adminNavBar.css'
export default {
  name: "AdminNavBar",
  mixins: [isAuth],
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      window.location.href = '/admin/login';
    },
    homePage(){
      window.location.href = '/admin';
    },
  },
  mounted() {
    this.checkAuth();
  }
};
</script>

<style scoped>
.item {
  margin-right: auto;
}

.logout-icon img {
  width: 24px;
  cursor: pointer;
  height: 36px;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px; /* Фиксированная ширина панели */
  background-color: #003366; /* Основной цвет */
  color: white;
  overflow-y: auto; /* Добавляем прокрутку по вертикали */
  border-right: 1px solid #ccc;
}
/*Надо тестить*/
.navbar-content {
  margin-left: 250px;
  padding: 20px;
}
.navbar img {
  display: block;
  margin: 20px auto;
  width: 150px;
}
.navbar::-webkit-scrollbar {
  display: none;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
}

.navbar li {
  margin: 15px 0;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 10px;
  display: block;
}

.navbar a:hover {
  background-color: #00539b; /* Дополнительный цвет */
  border-radius: 5px;
}

.logout {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  text-decoration: underline;
}



</style>
