<template>
  <admin-nav-bar />

  <div class="admin-content">
    <div class="d-flex justify-content-center mb-4">
      <router-link class="btn btn-primary" to="/admin/addDetailedHseGrand">Добавить</router-link>
    </div>
    <div class="card-grid">
      <div v-for="(hse_d, index) in hse_detailed" :key="index" class="card">
        <div class="card-body">
          <h5 class="card-title">
            {{ hse_d.hse_name ? hse_d.hse_name.replace(/<[^>]*>/g, '').substring(0, 40) : 'Название отсутствует' }}
          </h5>
          <p class="card-text">
            {{ hse_d.website_info ? hse_d.website_info.replace(/<[^>]*>/g, '').substring(0, 40) : 'Описание отсутствует' }}
          </p>
          <div class="card-actions">
            <router-link class="btn btn-primary" :to="{ name: 'hse_detailed_grand', params: { id: hse_d.id } }">
              Редактировать
            </router-link>
            <button class="btn btn-danger" type="button" @click="confirmDelete(hse_d.id)">Удалить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import {isAuth} from "@/mixins/authCheck";
import AdminNavBar from "@/components/AdminNavBar.vue";

export default {
  name: "ListDetailedHseGrandAdmin",
  mixins: [isAuth],
  components: {
    AdminNavBar
  },
  data() {
    return {
      hse_detailed: []
    };
  },
  methods: {
    getHseDetailed() {
      http
          .get("/hse_grandees_detailed")
          .then(async (response) => {
            this.hse_detailed = response.data;
            for (let i = 0; i < this.hse_detailed.length; i++) {
              try {
                const hd = this.hse_detailed[i];
                const grandeesResponse = await http.get(`/hse_grand/${hd.hse_grandees_id}`);
                hd.hse_name = grandeesResponse.data.format_name;
              } catch (e) {
                console.log(e);
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    async deleteHseDetailed(id) {
      try {
        await http.post(`/deleteHse_grand_detailed/${id}`);
        this.getHseDetailed();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete(id) {
      if (confirm("Вы уверены, что хотите удалить этот элемент?")) {
        this.deleteHseDetailed(id);
      }
    }
  },
  mounted() {
    this.checkAuth();
    this.getHseDetailed();
  }
};
</script>

<style scoped>
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Карточки адаптируются под ширину экрана */
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #003366; /* Цвет брендбука */
}

.card-text {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #555;
}

.card-actions {
  display: flex;
  justify-content: space-between;
}
</style>